import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { ModalService } from '../modal.service';

@Component({
  selector: 'app-modal-info',
  templateUrl: './modal-info.component.html',
  styleUrls: ['./modal-info.component.css']
})
export class ModalInfoComponent implements OnInit {




  @ViewChild('showModalButton') myModal: ElementRef;

  @Input()
  header = "temph";

  @Input()
  text = "tempt";

  @Input()
  autoShow;


  modalId: string;

  constructor(private modalS:ModalService) {



    for (let index = 0; index < 50; index++) {

      var uuid = Math.random().toString(36).slice(-6);

      //console.log(uuid);


    }
    var uuid = Math.random().toString(36).slice(-6);

    //console.log(uuid);
    this.modalId = uuid;


  }

  ngOnInit() {
  }
  ngAfterViewInit() {

  if(this.autoShow)  setTimeout(() => {
      this.myModal.nativeElement.click();;
    }, 500)
  }

  toggleModal() {
    //console.log("ooo")
  }
}
