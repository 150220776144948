import { Component, OnInit, Input } from '@angular/core';
import { StepService } from '../step.service';

@Component({
  selector: 'app-hdms-select',
  templateUrl: './hdms-select.component.html',
  styleUrls: ['./hdms-select.component.css']
})
export class HdmsSelectComponent implements OnInit {



  sorted() {
    return this.step.round.process.statements.sort(
      function (a, b) {


        return b.score - a.score;
      }
    )
  }


  @Input() currentPEval;

  constructor(public step:StepService) { }

  ngOnInit() {
  }

}
