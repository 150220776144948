import { Component, OnInit, Input } from '@angular/core';
import { StepService } from '../step.service';

@Component({
  selector: 'app-step-help',
  templateUrl: './step-help.component.html',
  styleUrls: ['./step-help.component.css']
})
export class StepHelpComponent implements OnInit {

@Input()
actor;

  constructor(public step:StepService) { }

  ngOnInit() {
  }

}
