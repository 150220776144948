import { Component, OnInit, Input } from '@angular/core';
import { StepService } from '../step.service';

@Component({
  selector: 'app-hdms-result',
  templateUrl: './hdms-result.component.html',
  styleUrls: ['./hdms-result.component.css']
})
export class HdmsResultComponent implements OnInit {
  @Input() currentPEval;


  
  checkSelected(statement) {
    return statement.selected;
  }


  filtred(){

    if(this.currentPEval) 
    return  this.currentPEval.statements.filter(this.checkSelected);
    return null;
  }
  constructor(public step:StepService) { }

  ngOnInit() {
  }

  getSelected(item){
//console.log(item)


if(item.solutions && item.solutions.length>0) return item.solutions[0].text;
    return "";
  }

}
