import { Component, OnInit, Input } from '@angular/core';
import { StepService } from '../step.service';
import { THROW_IF_NOT_FOUND } from '@angular/core/src/di/injector';
import { DataService } from '../data.service';
import { fade, slideUp } from '../animations/animations';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-hdms-hummanass',
  templateUrl: './hdms-hummanass.component.html',
  styleUrls: ['./hdms-hummanass.component.css'],
  animations: [
    fade,
    slideUp
  ]
})
export class HdmsHummanassComponent implements OnInit {
  ratings = ["", 1, 2, 3, 4]; //todo to service

  @Input() currentPEval;
  agree: boolean;



  constructor(public step: StepService, private data: DataService,private afs:AngularFirestore) {


  }


  ngOnInit() {
    //  //console.log("cp", this.currentPEval)

    // setTimeout(() => {
    //   //console.log('timeout', this.currentPEval.statements)

    //   this.currentPEval.statements.forEach(st => {
    //   //console.log("statement.ass", st.humanAss.length);

    // // st.commonHuman=  this.calcHumanAvg(st);
    //   });

    // }, 1000);

    //  setTimeout(this.iterateStatements,2000);
    //  this.iterateStatements();
    // //console.log('init')

    if (this.currentPEval) this.currentPEval.statements.forEach(statement => {
      statement.humanAssExpanded = false;

      // //console.log(statement);
    });
  }

  changeScore(item) {



    //console.log(item);

  }


  nextStep() {
    this.currentPEval.statements.forEach(statement => {
      statement.score = this.score(statement);
    });

    this.step.setStep('1b');
  }

  score(value: any) {

    let values = [];

    if (value.commonHuman) values.push(value.commonHuman)
    if (value.commonMachine) values.push(value.commonMachine)

    let total = 0;
    let count = 0;
    values.forEach(v => {
      let value = parseFloat(v);

      if (!isNaN(value) && value > 0) {
        total += value;
        count++;
      }

    });

    let avg: number = total / count;

    return avg;
  }

  setChangedScore(item, score, statement) {

    item.changedrating = score;
let assId=item.id;
    console.log("assid: ", assId)
        // .collection("statementAsses")
this.afs.collection("statementAsses").doc(assId).update({changedrating:score})

    this.change(statement);
  }


  change(statement) {



   // this.data.calcHumanAvg(statement);

  }


  iteratheStatements() {
    this.currentPEval.statements.forEach(statement => {
      // statement.commonHuman = this.calcHumanAvg(statement);
    });



  }

  setAgree(agree:boolean,item) {
    //console.log("agres " + agree)
  item.agree = agree;
  }

}
