import { Component, OnInit } from '@angular/core';
import { ExcelService } from '../excel.service';

@Component({
  selector: 'app-temp-test',
  templateUrl: './temp-test.component.html',
  styleUrls: ['./temp-test.component.css']
})
export class TempTestComponent implements OnInit {

  constructor(public excel: ExcelService) { }

  ngOnInit() {
  }


  onFileChange(evt: any) {

    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      //todo lägg i score servive
      try {
        this.excel.handleFile(e);
      } catch (err) {

      }

    };

    reader.readAsBinaryString(target.files[0]);
  }

}
