import {
  Component,
  OnInit,
  AfterContentChecked,
  Input,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { DummyService } from "../dummy.service";
import { Chart } from "chart.js";



@Component({
  selector: 'app-new-idea-chart',
  templateUrl: './new-idea-chart.component.html',
  styleUrls: ['./new-idea-chart.component.css']
})
export class NewIdeaChartComponent implements OnInit, AfterContentChecked {
  showTable: boolean = false;


  @Input() yAxis: string;
  @Input() xAxis: string;
  @Input() zAxis: string;


  ngAfterContentChecked(): void { }
  ngOnChanges(changes: any) {

    this.setData();
  }

  setData() {
    this.updatechart2();
  }
  @Input() data: any;
  @Input() type: any;
  //@Input() graphtitle: any;
  @Input() ideas: any;
  @Input() solutions: any;
  @Input() graphtype: any;
  yLabel="y";
     xLabel="x";
  chart: any;

  options = {}

  radi = 20;
  constructor(private dummy: DummyService) {

  }

  ngOnInit() {


    this.xLabel=this.xAxis;
    this.yLabel=this.yAxis;
    if(this.xAxis.toLowerCase()=="risk") this.xLabel="Risk";
    if(this.yAxis.toLowerCase()=="risk") this.yLabel="Risk";


    this.options = {


      aspectRatio: 1,
      maintainAspectRatio: true,
      showTooltips: true,

      onAnimationComplete: function () {
        this.showTooltip(this.datasets[0].points, true);
      },
      onClick: (e, el) => {


        var activeElement = el[0];

        if (!activeElement) return;
        var data = activeElement._chart.data;


        var barIndex = activeElement._index;
        var datasetIndex = activeElement._datasetIndex;

        var datasetLabel = data.datasets[datasetIndex].label;
        var xLabel = data.labels[barIndex];
        var yLabel = data.datasets[datasetIndex].data[barIndex];

        console.log(datasetLabel, xLabel, yLabel);
        //   const canvasPosition = Chart.helpers.getRelativePosition(e, this.chart);

        // // Substitute the appropriate scale IDs
        //  const dataX = this.chart.scales.x.getValueForPixel(canvasPosition.x);

        //console.log("datax",dataX)
        // const dataY = chart.scales.y.getValueForPixel(canvasPosition.y);
      }
      ,

      //     onHover:(e)=>{
      // console.log("hover!",e)
      //     },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            //    console.log("hover2",tooltipItem)
            return (
              data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                .name +
              "\n " +
              ""
            );
          },



        },
      },

      chartArea: {
        backgroundColor: "lightgrey",
      },

      elements: {
        point: {
          borderWidth: 5,
          hoverBorderWidth: 10,
          radius: this.radi,
        },
      },
      scales: {
        yAxes: [
          {  afterTickToLabelConversion: function(scaleInstance) {
            // set the first and last tick to null so it does not display
            // note, ticks[0] is the last tick and ticks[length - 1] is the first
            scaleInstance.ticks[0] = null;
            scaleInstance.ticks[scaleInstance.ticks.length - 1] = null;
  
            // need to do the same thing for this similiar array which is used internally
            scaleInstance.ticksAsNumbers[0] = null;
            scaleInstance.ticksAsNumbers[scaleInstance.ticksAsNumbers.length - 1] = null;
          },
            ticks: {
              min: 0,
              // max: 25,
              max: 6,
            },
            scaleLabel: {
              display: true,
              labelString: this.capitalizeFirstLetter(this.yLabel)
            }
          },
        ],
        xAxes: [
          {  afterTickToLabelConversion: function(scaleInstance) {
            // set the first and last tick to null so it does not display
            // note, ticks[0] is the last tick and ticks[length - 1] is the first
            scaleInstance.ticks[0] = null;
            scaleInstance.ticks[scaleInstance.ticks.length - 1] = null;
  
            // need to do the same thing for this similiar array which is used internally
            scaleInstance.ticksAsNumbers[0] = null;
            scaleInstance.ticksAsNumbers[scaleInstance.ticksAsNumbers.length - 1] = null;
          },
            ticks: {
              min: 0,
              max: 6,
              //max: 25,
            },
            scaleLabel: {
              display: true,
              labelString: this.capitalizeFirstLetter(this.xLabel)
            }
          },
        ],
      },
      plugins: {
        datalabels: {
          anchor: function (context) {
            var value = context.dataset.data[context.dataIndex];
            return value.x < 1000 ? 'end' : 'center';
          },
          align: function (context) {
            var value = context.dataset.data[context.dataIndex];
            return value.x < 1000 ? 'end' : 'center';
          },
          color: function (context) {
            var value = context.dataset.data[context.dataIndex];
            return value.x < 1000 ? context.dataset.backgroundColor : 'white';
          },
          font: {
            weight: 'bold'
          },
          formatter: function (value, context) {
            return 'test'//context.dataset.label;
          },
          offset: 2,
          padding: 0
        }
      },
    };
  }


  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  @ViewChild("graphCanvas") graphCanvas: ElementRef;
  ngAfterViewInit() {
    //https://blog.angular-university.io/angular-debugging/
    setTimeout(() => {
      var g;

      ////////////////
      Chart.pluginService.register({
        beforeDraw: function (chart, easing) {
          if (
            chart.config.options.chartArea &&
            chart.config.options.chartArea.backgroundColor
          ) {
            var helpers = Chart.helpers;
            var ctx = chart.chart.ctx;
            var chartArea = chart.chartArea;

            //  g= ctx.createLinearGradient(0,0,ctx.canvas.width,0);

            //     g.addColorStop(0, 'white');
            //     g.addColorStop(1, 'blue');

            ctx.save();
            ctx.fillStyle = chart.config.options.chartArea.backgroundColor;
            ctx.fillRect(
              chartArea.left,
              chartArea.top,
              chartArea.right - chartArea.left,
              chartArea.bottom - chartArea.top
            );

            ctx.restore();
          }
        },

        /////////////////////////

        afterDatasetsDraw: function (chart, easing) {
          var ctx = chart.ctx;

          chart.data.datasets.forEach(function (dataset, i) {

            var meta = chart.getDatasetMeta(i);
            if (meta.type == "bubble") { //exclude scatter
              meta.data.forEach(function (element, index) {
                // Draw the text in black, with the specified font
                ctx.fillStyle = 'rgb(10, 10, 10)';
                var fontSize = 10;
                var fontStyle = 'normal';
                var fontFamily = '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';
                ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily);

                // Just naively convert to string for now
                var dataString = dataset.data[index];
                // Make sure alignment settings are correct
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';

                var padding = 15;
                var position = element.tooltipPosition();

                ctx.fillText(dataString.title, position.x, position.y);
                // ctx.fillText(dataset.title, position.x, position.y - (fontSize / 2) - padding);
              });
            } //if
          });
        }

      });


      


      //data wa shere
      let data = this.getData();

      console.log("DATA",data)
      
      this.chart = new Chart(this.graphCanvas.nativeElement, {
        plugins: [{
          //////


          beforeDatasetDraw: chart => {
            const { datasets } = chart.config.data;

        //    console.log("dataset", datasets)
            const coordinateMap = []; // holds array of unique coord objects
            // datasets.forEach(d => {
            //   console.log("d", d)
            //   if (d._meta[0]?.data) {
            //     let elements = d._meta[0].data;
            //     elements.forEach((el, i) => {
            //       let { x, y } = el._model;
            //       const overlap = coordinateMap.find(coord => coord.x === x && coord.y === y);
            //       if (overlap) { // Overlap detected!
            //         // Update coordinate map
            //         x += 1;
            //         y += 1;
            //         // Jitter the x,y positioning - not working!
            //         d._meta[0].data[i]._model.x = x;
            //         d._meta[0].data[i]._model.y = y;
            //       }
            //       coordinateMap.push({ x, y });
            //     });
            //   }

            // });


            datasets.forEach(d => {
// console.log("dataset",d )
// console.log("dataset._meta",d._meta )

// d._meta.data.forEach(m => {
//   console.log("meta",m )
//               });

              
            });

     if(datasets.data)       for (var i = 0; i < datasets.data.length; i++) {
              for(var key in datasets._meta)
              {
                  //var model = datasets._meta[key].data[i]._model;
                  var model = datasets._meta[key].data[i];//._model;

                  console.log("model" ,model)
                 // ctx.fillText(dataset.data[i], model.x, model.y - 5);
              }
          }

          }



          /////
        }],
        type: "bubble",
        data: {
          labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
          datasets: [
            {
              backgroundColor: "white",
              //  backgroundColor:'linear-gradient(orange, yellow)',
              label: this.capitalizeFirstLetter(this.xLabel) + " / " + this.capitalizeFirstLetter(this.yLabel), //this.graphtitle,
              data: data
            },
          ],
        },
        options: this.options,
      });





      var gradient = this.graphCanvas.nativeElement
        .getContext("2d")
        .createLinearGradient(
          0,
          this.chart.canvas.height,
          this.chart.canvas.width,
          0
        );

      gradient.addColorStop(1, "green");
      gradient.addColorStop(0.5, "orange");
      gradient.addColorStop(0, "red");

      this.chart.options.chartArea.backgroundColor = gradient;
      this.chart.update();

      ///////////////////
    }, 1);
  }

  getData() {
    let data = [];

    if (!this.solutions || this.solutions.length == 0) return data;
    let id = 1;
    this.solutions.forEach((s) => {

      data.push(
        this.calcDataNew(
          s.parameters.evalFactors,
          s.text,
          id.toString()
        )
      );
      id++;
    });

    console.log("chart", this.chart)
    //   this.chart.scales.yAxes[ 0 ].scaleLabel.labelString = "New Label";
    return data;
  }


  updatechart2() {
    console.log("uc2")
    let data = this.getData();

    if (!this.chart) {
      return;
    }

    this.chart.data.datasets.forEach((dataset) => {
      dataset.data.pop();
    });

    this.chart.data.datasets.forEach((dataset) => {
      dataset.data = [];

      //  dataset.title="banan2"
      dataset.data.forEach(element => {
        //element.title="banan3"
      });
    });

    this.chart.data.datasets.forEach((dataset) => {


      data.forEach(element => {

        //element.title="banan"
        console.log(element)
        dataset.data.push(element);
      });


    });

    this.chart.update();
  }


  calcDataNew(factors: any[], text: string, id: string) {


    let r = this.radi;

    if (this.zAxis == "risk") {
      r = Math.random() * 100;
    }
    let s = {
      name: text,
      id: id,
      x: 0,
      y: 0,
      r: r,
      title: 'Idea ' + id,

    }



    let x = this.xAxis;
    let y = this.yAxis;
    let z = this.zAxis;


    s.x = this.getAvgFromFactorArrayOnAxis(factors, x);
    s.y = this.getAvgFromFactorArrayOnAxis(factors, y);

    if (z) {
      let zzz = this.getAvgFromFactorArrayOnAxis(factors, z);
      console.log("zzzz", zzz)


      r = 20 / zzz + 10;

      s.r = r;
    }
    return s;
  }

  getAvgFromFactorArrayOnAxis(factors: any[], axis: string) {
    return this.getAvgValueFromFactorArray(factors.filter(cur => cur.axis == axis));
  }

  getAvgValueFromFactorArray(arr: any[]) {
    return arr.reduce((acc, curr) => {
      return acc + curr.value;
    }, 0) / arr.length;

  }


}
