import { Component, OnInit } from '@angular/core';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';
import { AngularFireFunctions } from "@angular/fire/functions";
@Component({
  selector: 'app-tester',
  templateUrl: './tester.component.html',
  styleUrls: ['./tester.component.css']
})
export class TesterComponent implements OnInit {
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  uploadProgress: Observable<number>;
  downloadURL: Observable<any>;

  prompt: string = "Solve problem: Most tickets are not assigned a priority.";
  result: string = "";
  thinking: boolean = false;

  constructor(private af: AngularFireFunctions) { }

  ngOnInit() {
  }



  async sendPrompt() {
    if (this.prompt.length == 0) return;
    this.thinking = true;
    const createCompletionRequest = {
      model: "text-davinci-002",
      prompt: this.prompt,
      temperature: 0.3,
      max_tokens: 120,
      top_p: 1.0,
      frequency_penalty: 0.8,
      presence_penalty: 0.0,

    };

    let r = await this.af.httpsCallable("solve")(
      {
        createCompletionRequest: createCompletionRequest
      }
    );

    let a = await r.toPromise();
    let c = a.choices[0];
    console.log("yo!", c.text)

    this.result = c.text;
    this.thinking = false;



    
  }
  // upload = (event) => {
  //   //console.log("Upload!",event)
  //   // create a random id
  //   const randomId = Math.random().toString(36).substring(2);
  //   // create a reference to the storage bucket location
  //   this.ref = this.afStorage.ref('/images/' + randomId);
  //   // the put method creates an AngularFireUploadTask
  //   // and kicks off the upload
  //   this.task = this.ref.put(event.target.files[0]);

  //   // AngularFireUploadTask provides observable
  //   // to get uploadProgress value
  //   this.uploadProgress = this.task.percentageChanges();




  //   this.task.snapshotChanges().pipe(
  //     finalize(() => this.downloadURL = this.ref.getDownloadURL())
  //   )
  //   .subscribe();

  // }

}
