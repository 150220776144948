import { Component, OnInit, Input } from '@angular/core';
import { StepService } from '../step.service';

@Component({
  selector: 'app-step-header',
  templateUrl: './step-header.component.html',
  styleUrls: ['./step-header.component.css']
})
export class StepHeaderComponent implements OnInit {
  showHelp: boolean = false;



  //   @Input()
  //   step;

  //   @Input()
  //   header;

  //   @Input()
  //   subheader;

  //   @Input()
  //   roundname;

  // @Input()
  //   infotext;

  // @Input()
  //   phase:number;

  @Input()
  actor;

  constructor(public step: StepService) { }

  ngOnInit() {
  }


  handleClick(evt) {
    this.showHelp = !this.showHelp
    evt.stopPropagation();
  }
}