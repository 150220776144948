import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-machine-info-and-graph',
  templateUrl: './machine-info-and-graph.component.html',
  styleUrls: ['./machine-info-and-graph.component.css']
})
export class MachineInfoAndGraphComponent implements OnInit {


  @Input()
  item: any;

  showGraph:boolean=true;
  moreDetails:boolean=false;
  constructor() { }

  ngOnInit() {
  }

}
