import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class ScoreService {


  data: any;
  results: any[] = [];
  tickets: XLSX.WorkSheet;
  process: any;

  tries: any[] = [];

  scoringResult;
  scoringResultInfo: string;
  filename: string;




  handleFile(e: any, process, file) {

    console.log("foo1", file)

    this.scoringResult = 0;
    this.scoringResultInfo = "Trying to calculate...";

    this.process = process;
    //console.log("Calculating scores for ", process)

    /* read workbook */
    const bstr: string = e.target.result;
    const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

    /* grab first sheet */

    const sheets = ['Tickets', 'Alerts'];

    this.data = {};

    sheets.forEach(wsname => {
      // const wsname: string = 'Tickets';// wb.SheetNames[1];
      this.tickets = wb.Sheets[wsname];


      /* save data */
      this.data[wsname] = {
        headers: [],
        rows: (XLSX.utils.sheet_to_json(this.tickets, { header: 1 }))
      };


      this.data[wsname].headers.push(this.data[wsname].rows.shift());
    });


    this.filename = this.saveData(this.data, file);

    //console.log("foo2",process)

    process.statements.forEach(s => {
      switch (s.sid) {
        // case '1':
        //   s.machineScore = this.calcStatementScore_AlertsResolvedWithin(24);
        //   break;
        // case '2':
        //   s.machineScore = this.calcStatementScore_CategoryPercentage(); //todo
        //   break;
        // case '3':
        //   s.machineScore = this.calcStatementScore_AlertsOfType('Error');
        //   break;
        // case '4':
        //   s.machineScore = this.calcStatementScore_CountIncidents(); //todo
        //   break;
        // case '5':
        //   s.machineScore = this.calcStatementScore_TicketsResolvedWithin(24);
        //   break;
        // case 9: //old 6
        //   s.machineScore = this.calcStatementScore_SolvedTicketsAreClosed();
        //  break;
        // case 6: //old 7
        //   s.machineScore = this.calcStatementScore_CountSupportGroup();
        //   break;
        case 9://old 10://old 8
          s.machineScore = this.calcStatementScore_PriorityAssigned();
          break;

        // case '9':
        //   s.machineScore = this.calcStatementScore_UniqueIds();
        //   break;


        default:
          break;
      }

    });
    let failed = 0;
    let success = 0;


    process.statements.forEach(s => {

      console.log(s);

      if (s.machineScore === null) failed++;
      else {
        success++;

      }


    });
    console.log("foo3")


    //this.scoringResult = "Scored " + success + "/" + (failed + success) + " statements.";

    if (success < 9) {
      this.scoringResult = "-1";
      this.scoringResultInfo = "Failed calculating score."
    } else {
      this.scoringResult = "1";
      this.scoringResultInfo = ""
    }

    // this.calcStatementScore_CountSupportGroup();
    // this.calcStatementScore_AlertsResolvedWithin(24);
    //this.calcStatementScore_TicketsResolvedWithin(24);
    // this.calcStatementScore_AlertsOfType('Error');
    // this.calcStatementScore_SolvedTicketsAreClosed();
    //this.calcStatementScore_UniqueIds();
  }


  saveData(data: any, file) {
    let randomId = this.afs.createId + "_";
    randomId = "foobar2";
    randomId = Math.random().toString(36).substring(2) + "_" + file.name;
    let ref = this.storage.ref('/xls/' + randomId);

    var metadata = {
      customMetadata: {
        filename: file.name
      }
    };


    let task = ref.put(file, metadata);

    return randomId;
  }


  handleFileNoProcess(e: any) {

    /* read workbook */
    const bstr: string = e.target.result;
    const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

    /* grab first sheet */
    const sheets = ['Tickets', 'Alerts'];

    this.data = {};

    sheets.forEach(wsname => {
      // const wsname: string = 'Tickets';// wb.SheetNames[1];
      this.tickets = wb.Sheets[wsname];


      /* save data */
      this.data[wsname] = {
        headers: [],
        rows: (XLSX.utils.sheet_to_json(this.tickets, { header: 1 }))
      };


      this.data[wsname].headers.push(this.data[wsname].rows.shift());
    });


    // this.scoringResult = "Scored " + success + "/" + (failed + success) + " statements.";

    this.calcStatementScore_CountSupportGroup();
    this.calcStatementScore_AlertsResolvedWithin(24);
    this.calcStatementScore_TicketsResolvedWithin(24);
    this.calcStatementScore_AlertsOfType('Error');

    this.calcStatementScore_SolvedTicketsAreClosed();
    this.calcStatementScore_UniqueIds();
    this.calcStatementScore_CategoryPercentage();
    this.calcStatementScore_CountIncidents();
    this.calcStatementScore_PriorityAssigned();
  }

  calcStatementScore_PriorityAssigned() {

    let labels = [];
    let values = [];

    let data = this.data['Tickets'];

    const priority = this.findColumnIndex('Tickets', 'priority');

    if (priority === -1) {
      alert("Could not find column 'priority' in 'Tickets'");
      return;
    }

    let prios = {};

    let totalPrio = 0;
    for (let index = 0; index < data.rows.length; index++) {
      const row = data.rows[index];

      let prio = row[priority];


      if (prio === undefined) continue;
      if (prio.toUpperCase() === 'NULL') continue;



      if (prios[prio]) prios[prio]++;
      else prios[prio] = 1;

      totalPrio++;
    }

    let text = [];
    let shortInfo=[];

    let rows = data.rows.length;


    text.push(
      'There are totally ' + rows + ' tickets records;'
    )
    text.push('');
    for (var key in prios) {
      if (prios.hasOwnProperty(key)) {
        //console.log(key + ": " + prios[key]);

        let count = prios[key];

       // labels.push(key);  //todo ful fix
        labels.push("Assigned priority");  //todo ful fix
        values.push(count);
        text.push(
          (count / rows * 100).toFixed(1) + '% are assigned a priority type "' + key + '";'
        )
      }
    }


    labels.push('No priority');
    values.push(rows - totalPrio)
    let percent = ((totalPrio / rows * 100));

shortInfo.push(percent+'% are assigned a priority.');

    text.push('');
    text.push(
      (100 - percent).toFixed(1) + '%  without priority.'
    )
    let result = {
      statement: 'All tickets are assigned a priority.',
      desc: '',
      percent,
      graphData: { labels, values },
      data: {
        percent,
        shortInfo:shortInfo,
        text,
        tickets: data.rows.length,
        prios
      },
      score: this.calcScore(percent)
    }

    this.results.push(result);
    return result;



  }



  calcStatementScore_CountTicketTypes() {
    let data = this.data['Tickets'];
    const ticketType = this.findColumnIndex('Tickets', 'ticketType');

    if (ticketType === -1) {
      alert("Could not find column 'ticketType' in 'Tickets'");
      return;
    }

    let ticketTypes = {};

    for (let index = 0; index < data.rows.length; index++) {
      const row = data.rows[index];
      let tt = row[ticketType];
      if (ticketTypes[tt]) ticketTypes[tt]++;
      else ticketTypes[tt] = 1;
    }

    let value = 1;

    let percent: number = (value * 100);
    let rows = data.rows.length;
    let text = ['There are totally ' + rows + ' alert records;'];

    for (var key in ticketTypes) {
      if (ticketTypes.hasOwnProperty(key)) {
        let count = ticketTypes[key];
        text.push(
          count + ' alerts (' + (count / rows * 100).toFixed(1) + '%) belongs to the category "' + key + '".'
        )
      }
    }

    let result = {
      statement: 'ticket types',
      desc: '',
      percent: value * 100,
      data: {
        ticketTypes,
        text
      },
      score: this.calcScore(percent)
    }

    this.results.push(result);
    return result;
  }



  calcStatementScore_CountIncidents() {
    let data = this.data['Tickets'];
    const ticketType = this.findColumnIndex('Tickets', 'ticketType');
    const companyName = this.findColumnIndex('Tickets', 'companyName');

    if (ticketType === -1) {
      alert("Could not find column 'ticketType' in 'Tickets'");
      return;
    }

    if (companyName === -1) {
      alert("Could not find column 'companyName' in 'Tickets'");
      return;
    }

    let companies = {};
    let incidentCount = 0;

    for (let index = 0; index < data.rows.length; index++) {
      const row = data.rows[index];
      let tt = row[ticketType];
      let company = row[companyName];


      if (tt !== 'Incident') continue;
      incidentCount++;

      if (companies[company]) companies[company]++;
      else companies[company] = 1;
    }

    let value = 1;


    let rows = data.rows.length;
    let text = ['There are totally ' + incidentCount + ' incidents occurred in this time period.'];
    text.push('');

    let nCompanies = 0;
    let normalIncidents = 0;

    let labels = [];
    let values = [];



    for (var key in companies) {
      if (companies.hasOwnProperty(key)) {
        nCompanies++;
        let count = companies[key];
        let p = (count / incidentCount * 100);


        //  if (p < 5) normalIncidents++;
        if (count < 5) normalIncidents++;

        labels.push(key);
        values.push(count);


        text.push(
          count + ' incidents (' + p.toFixed(1) + '%)  occurred in comany   "' + key + '".'
        )
      }
    }

    text.push('');
    text.push(
      normalIncidents + ' of ' + nCompanies + ' (' + (normalIncidents / nCompanies * 100).toFixed(1) + '%) companies have normal incidents.'
    )


    let percent = (normalIncidents / nCompanies * 100);

    let result = {
      statement: 'The amount of incidents occurred for each company  during the time period is normal.',
      desc: '',
      percent,
      graphData: { labels, values },
      data: {
        percent,
        companies,
        text,
        nCompanies,
        normalIncidents

      },
      score: this.calcScore(percent)
    }

    this.results.push(result);
    return result;
  }


  calcStatementScore_CategoryPercentage() {
    let data = this.data['Alerts'];
    const category = this.findColumnIndex('Alerts', 'category');

    if (category === -1) {
      alert("Could not find column 'category' in 'Alerts'");
      return;
    }

    let cats = {};

    for (let index = 0; index < data.rows.length; index++) {
      const row = data.rows[index];
      let cat = row[category];
      if (cats[cat]) cats[cat]++;
      else cats[cat] = 1;
    }

    let value = 1;

    // let percent: number = (value * 100);
    let rows = data.rows.length;
    let text = ['There are totally ' + rows + ' alert records;'];

    text.push('');
    let nCats = 0;
    let normalCats = 0;


    let labels = [];
    let values = [];

    for (var key in cats) {
      if (cats.hasOwnProperty(key)) {
        let count = cats[key];
        nCats++;
        let p = (count / rows * 100);

        if (p < 50) {
          normalCats++;
        }


        labels.push(key);
        values.push(count);

        text.push(

          //  count + ' alerts (' + (count / rows * 100).toFixed(1) + '%) belongs to the category "' + key + '".'
          'category "' + key + '": ' + count + ' alerts (' + (count / rows * 100).toFixed(1) + '%)'
        )
      }
    }

    let percent = normalCats / nCats * 100;
    text.push('');
    text.push(
      percent.toFixed(1) + '% categories have a normal amount of alerts.'
    )



    let result = {
      statement: 'The amount of alerts in each category are normal.',
      desc: '',
      percent: percent,
      graphData: { labels, values },
      data: {
        cats,
        text
      },
      score: this.calcScore(percent)
    }

    this.results.push(result);
    return result;
  }


  constructor(private afs: AngularFirestore, private storage: AngularFireStorage) { }


  calcStatementScore_UniqueIds() {

    let labels = [];
    let values = [];

    let data = this.data['Tickets'];

    let ticketsCount = data.rows.length;
    let ticketsIds = [];
    let ticketsDuplicates = [];

    const ticketRequestID = this.findColumnIndex('Tickets', 'ticketRequestID');
    const alertID = this.findColumnIndex('Alerts', 'alertID');



    if (ticketRequestID === -1) {
      alert("Could not find column 'ticketRequestID' in 'Tickets'");
      return;
    }


    for (let index = 0; index < data.rows.length; index++) {
      const row = data.rows[index];

      let id = row[ticketRequestID];

      if (ticketsIds.includes(id)) {
        ticketsDuplicates.push[id]

      } else {
        ticketsIds.push(id);
      }

    }

    data = this.data['Alerts'];

    let alertCount = data.rows.length;
    let alertIds = [];
    let alertsDuplicates = [];

    for (let index = 0; index < data.rows.length; index++) {
      const row = data.rows[index];

      let id = row[alertID];

      if (alertIds.includes(id)) {
        alertsDuplicates.push[id]

      } else {
        alertIds.push(id);
      }

    }

    let text = [];

    let alertPercentage = (alertCount - alertsDuplicates.length) / alertCount * 100;
    let tickerPercentage = (ticketsCount - ticketsDuplicates.length) / ticketsCount * 100;
    let totalPercentage = (ticketsCount + alertCount - ticketsDuplicates.length - alertsDuplicates.length) / (ticketsCount + alertCount) * 100;

    text.push(
      'There are totally ' + alertCount + ' alert records;'
    )
    text.push(
      alertPercentage.toFixed(1) + '% alerts has an unique identifier.'
    )
    text.push(' ');

    text.push(
      'There are totally ' + ticketsCount + ' ticket records;'
    )
    text.push(
      tickerPercentage.toFixed(1) + '% tickets has an unique identifier.'
    )
    text.push('');

    text.push(
      totalPercentage.toFixed(1) + '% alerts or tickets have a unique identifier. '
    )

    labels.push('Has unique identifier')
    labels.push('Has not unique identifier')


    let notUnique = ticketsDuplicates.length - ticketsCount;


    values.push(ticketsCount + alertCount);
    values.push(ticketsDuplicates.length + alertsDuplicates.length);

    let result = {
      statement: 'There is an unique identifier for each alert or ticket.',
      desc: '',
      graphData: { labels, values },
      percent: totalPercentage,
      data: {
        text,
        ticketsCount,
        ticketDuplicates: ticketsDuplicates.length,
        alertCount,
        alertsDuplicates: alertsDuplicates.length,

      },
      score: this.calcScore(totalPercentage)

    }

    this.results.push(result);
    return result;

  }
  calcScore(percent: number) {

    if (!percent) return null;

    let score = 1;
    if (percent >= 50) score = 2;
    if (percent >= 80) score = 3;
    if (percent >= 100) score = 4;

    return score;
  }

  calcStatementScore_SolvedTicketsAreClosed() {


    let labels = [];
    let values = [];

    let data = this.data['Tickets'];



    const dateClosed = this.findColumnIndex('Tickets', 'dateClosed');
    const dateResolved = this.findColumnIndex('Tickets', 'dateResolved');



    if (dateResolved === -1) {
      alert("Could not find column 'dateResolved' in 'Tickets'");
      return;
    }

    if (dateClosed === -1) {
      alert("Could not find column 'dateClosed' in 'Tickets'");
      return;
    }





    let resolvedAcc = 0;
    let closedAcc = 0;
    for (let index = 0; index < data.rows.length; index++) {
      const row = data.rows[index];

      let t1 = new Date(row[dateResolved]).getTime();
      if (isNaN(t1)) continue;

      resolvedAcc++;



      let t2 = new Date(row[dateClosed]).getTime();



      if (!isNaN(t2)) {
        closedAcc++;

      }



    }


    //of total solved tickets
    //let value = closedAcc / resolvedAcc;
    //of total tickets
    let value = closedAcc / data.rows.length;


    let text = [];

    text.push(
      'There are totally ' + data.rows.length + ' ticket records;',
      (resolvedAcc / data.rows.length * 100).toFixed(1) + '% tickets are solved;',
      // (value * 100).toFixed(1) + '% tickets that are solved are also closed.'
      (value * 100).toFixed(1) + '% tickets that are solved and closed.' //todo  talk about
    )


    labels.push('Tickets that are solved and closed')
    labels.push('Tickets that are solved and NOT closed')


    values.push(closedAcc)
    values.push(resolvedAcc - closedAcc)  //todo dubbelkolla

    let result = {
      statement: 'Tickets that are solved are also closed.',
      desc: 'Tickets that are solved are also closed.',
      graphData: { labels, values },
      percent: value * 100,
      data: {
        text,
        ticketsOnlySolved: resolvedAcc - closedAcc,
        ticketsSolvedAndClosed: closedAcc,
      },
      score: this.calcScore(value * 100)

    }

    this.results.push(result);
    return result;

  }

  calcStatementScore_AlertsOfType(alertType: string) {

    let data = this.data['Alerts'];

    const severity = this.findColumnIndex('Alerts', 'severity');

    if (severity === -1) {
      alert("Could not find column 'severity' in 'Alerts'");
      return;
    }

    let sevs = {};

    for (let index = 0; index < data.rows.length; index++) {
      const row = data.rows[index];

      let sev = row[severity];

      if (sevs[sev]) sevs[sev]++;
      else sevs[sev] = 1;
    }

    let text = [];
    let rows = data.rows.length;

    text.push(
      'There are totally ' + rows + ' alert records;'
    )





    let labels = [];
    let values = [];



    let nSevs = 0;
    let normalSevs = 0;

    for (var key in sevs) {
      if (sevs.hasOwnProperty(key)) {

        let count = sevs[key];

        labels.push(key);
        values.push(count);

        nSevs++;
        let p = (count / rows * 100);

        if (p < 50) {
          normalSevs++;
        }





        text.push(
          count + ' alerts (' + (count / rows * 100).toFixed(1) + '%) are of the severity type "' + key + '".'
        )
      }
    }

    let percent = normalSevs / nSevs * 100;

    let result = {
      statement: ' Most alerts are of the severity type ‘Error’',
      desc: '',
      percent,
      graphData: { labels, values },
      data: {
        text,
        alerts: data.rows.length,
        sevs,
        normalSevs,
        nSevs
      },
      score: this.calcScore(percent)
    }

    this.results.push(result);
    return result;
  }

  findColumnIndex(sheet: string, column: string) {
    const index = this.data[sheet].headers[0].indexOf(column);

    return index;

  }

  calcStatementScore_AlertsResolvedWithin(timespan: number) {



    let data = this.data['Alerts'];



    const timeRaised = this.findColumnIndex('Alerts', 'timeRaised');
    const timeResolved = this.findColumnIndex('Alerts', 'timeResolved');

    const priority = this.findColumnIndex('Alerts', 'priority');



    if (priority === -1) {
      alert("Could not find column 'priority' in 'Alerts'");
      return;
    }
    if (timeRaised === -1) {
      alert("Could not find column 'timeRaised' in 'Alerts'");
      return;
    }

    if (timeResolved === -1) {
      alert("Could not find column 'timeResolved' in 'Alerts'");
      return;
    }



    let resolvedWithinTime = 0;

    let noPrio = true;

    for (let index = 0; index < data.rows.length; index++) {
      const row = data.rows[index];


      let t1 = new Date(row[timeRaised]).getTime();
      let t2 = new Date(row[timeResolved]).getTime();


      let p = row[priority];


      if (p) noPrio = false;

      if (!p) continue;

      if (isNaN(t1) || isNaN(t2)) {
        //console.log("NaN!", t1, t2);
        continue;
      }

      var time = t2 - t1;

      let timespanMillis = timespan * 60 * 60 * 1000;


      if (timespanMillis > time) resolvedWithinTime++;


    }

    // //console.log("rows: " + (data.rows.length) + " ok : " + accsupportGroups, data);

    let value = resolvedWithinTime / data.rows.length;

    let percent: number = (value * 100);
    let text = [];


    let labels = [];
    let values = [];


    labels.push('Result');

    if (noPrio) {
      text.push('There is no alert is prioritized. ');

      values.push('There is no alert is prioritized. ')
      percent = null;
    }
    else {

      text.push(
        'There are totally ' + data.rows.length + ' alert records.',
        percent.toFixed(1) + '% alerts with prioritisation are always resolved within 24 hours. '


      );
      values.push(percent.toFixed(1) + '% alerts with prioritisation are always resolved within 24 hours. ')

    }

    let result = {
      statement: 'Alerts with prioritisation are always resolved within the estimated time period.(24h)',
      desc: 'Alerts resolved within ' + timespan + 'h.',
      graphData: { labels, values },
      percent: value * 100,
      data: {
        alerts: data.rows.length,
        resolvedWithinTime: resolvedWithinTime,
        noPrio: noPrio, text: text,
      },


      score: this.calcScore(percent)


    }



    this.results.push(result);
    return result;
  }

  calcStatementScore_TicketsResolvedWithin(timespan: number) {



    let data = this.data['Tickets'];



    const dateCreated = this.findColumnIndex('Tickets', 'dateCreated');
    const dateResolved = this.findColumnIndex('Tickets', 'dateResolved');
    const ticketType = this.findColumnIndex('Tickets', 'ticketType');



    if (dateResolved === -1) {
      alert("Could not find column 'dateResolved' in 'Tickets'");
      return;
    }

    if (dateCreated === -1) {
      alert("Could not find column 'dateCreated' in 'Tickets'");
      return;
    }

    if (ticketType === -1) {
      alert("Could not find column 'ticketType' in 'Tickets'");
      return;
    }



    let resolvedWithinTime = 0;
    let incidentAcc = 0;
    let labels = [];
    let values = [];

    for (let index = 0; index < data.rows.length; index++) {
      const row = data.rows[index];

      if (row[ticketType] !== 'Incident') continue;

      let t1 = new Date(row[dateCreated]).getTime();
      let t2 = new Date(row[dateResolved]).getTime();


      incidentAcc++;
      if (isNaN(t1) || isNaN(t2)) {
        //   //console.log("NaN!", t1, t2);
        continue;
      }

      var time = t2 - t1;

      let timespanMillis = timespan * 60 * 60 * 1000;

      // //console.log("T1: " + t1);
      // //console.log("T2: " + t2);
      // //console.log("diff: " + time);
      // //console.log("ts: " + (timespanMillis - time));
      // //console.log("")

      if (timespanMillis > time) resolvedWithinTime++;
      //   //console.log(row[timeRaised], row[timeResolved], time,timespanMillis , time-timespanMillis);


    }

    // //console.log("rows: " + (data.rows.length) + " ok : " + accsupportGroups, data);

    let value = resolvedWithinTime / incidentAcc;
    let text = [];

    text.push(
      'There are totally ' + data.rows.length + ' ticket records;',
      (incidentAcc / data.rows.length * 100).toFixed(1) + "% tickets are in type 'incident';",
      (value * 100).toFixed(1) + "% tickets with type 'incident'  are solved within24h."
    )

    labels.push('Not solved within 24h');
    labels.push('Solved within 24h');

    values.push(incidentAcc - resolvedWithinTime);
    values.push(resolvedWithinTime);

    let result = {
      statement: 'All tickets with type "incident" are solved within agreed time period (24).',
      desc: 'Tickets  with type "incident" resolved within ' + timespan + 'h.',
      graphData: { labels, values },
      percent: value * 100,
      data: {
        text,
        tickets: incidentAcc,
        resolvedWithinTime: resolvedWithinTime,
      },
      score: this.calcScore(value * 100)

    }

    this.results.push(result);

    return result;
  }

  calcStatementScore_CountSupportGroup() {

    let labels = [];
    let values = [];




    const lookFor = "supportGroup";
    let lookForIndex;

    let data = this.data['Tickets'];

    lookForIndex = data.headers[0].indexOf(lookFor);


    if (lookForIndex === -1) {
      alert("Could not find column " + lookFor + " in Tickets");
      return;
    }

    let accsupportGroups = 0;
    for (let index = 0; index < data.rows.length; index++) {
      const row = data.rows[index];

      let supportGroup: string = row[lookForIndex];

      // if (Math.random() > .9) supportGroup = "";
      // if (Math.random() > .9) supportGroup = null;

      const ok = this.supportGroupOk(supportGroup);

      if (ok) accsupportGroups++;
      ////console.log(index, isString(supportGroup), ok);



    }

    // //console.log("rows: " + (data.rows.length) + " ok : " + accsupportGroups, data);

    let value = accsupportGroups / data.rows.length;

    let text = [];

    text.push(

      'There are totally ' + data.rows.length + ' ticket records;',
      (value * 100).toFixed(1) + '% tickets are assigned to a support group.'
    )

    labels.push('Tickets assigned to a support group')
    labels.push('Tickets not assigned to a support group')

    values.push(accsupportGroups);
    values.push(data.rows.length - accsupportGroups);


    let result = {
      statement: 'All tickets are assigned to a support group.',
      desc: 'Counts tickets assigned to supportGroup.',
      graphData: { labels, values },
      percent: value * 100,
      data: {
        text,
        tickets: data.rows.length,
        assignedToSupportGroup: accsupportGroups,
      },
      score: this.calcScore(value * 100)

    }

    this.results.push(result);
    return result;
  }

  supportGroupOk(supportGroup: string) {
    if (!supportGroup) return false;
    if (supportGroup.length === 0) return false;
    return true;
  }
}
