import { Component, OnInit } from "@angular/core";
import {
  AngularFirestoreCollection,
  AngularFirestore
} from "@angular/fire/firestore";

import { Observable } from "rxjs";

import { Router } from "@angular/router";

import { map } from "rxjs/operators";
import { Round } from "../round";

@Component({
  selector: "app-services",
  templateUrl: "./services.component.html",
  styleUrls: ["./services.component.css"]
})
export class ServicesComponent implements OnInit {
  services: Observable<any[]>;
  serviceCollection: any;
  processes: Observable<any[]>;

  constructor(private afs: AngularFirestore) {
    this.services = this.afs
      .collection<any>("services", ref =>
        ref.where("userid", "==", "y2wSMtxxOfburOuwYC7hCntVIFt1")
      )
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(a => {
            //     //console.log("am i running=")
            const data = a.payload.doc.data() as any;

            data.id = a.payload.doc.id;
            return data;
          });
        })
      );  
  }
  ngOnInit() {}



  delete(p) {
    if (
      confirm(
        'Are sure you want to delete "' + p.name + '"? This cannot be undone.'
      )
    ) {
      this.afs
        .collection("services")
        .doc(p.id)
        .delete();
    }
  }
}
