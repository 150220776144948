import { Component, OnInit, Input } from '@angular/core';
import { AppPage } from 'e2e/src/app.po';
import { sortedChanges } from '@angular/fire/firestore';
import { StepService } from '../step.service';
import { FactoryService } from '../factory.service';
import { AngularFireFunctions } from '@angular/fire/functions';





@Component({
  selector: 'app-hdms-solutions',
  templateUrl: './hdms-solutions.component.html',
  styleUrls: ['./hdms-solutions.component.css']
})
export class HdmsSolutionsComponent implements OnInit {

  public solutionIdeas = [
    'Change technology',
    'Change behaviour',
    'Change management',
    'Other',
  ]

  showAssInfo = false;

  @Input() currentPEval;


  problems;
  thinking: boolean = false;


  constructor(public step: StepService, private factory: FactoryService, private af: AngularFireFunctions) { }


  removeSolution(item, i) {

    item.solutions.splice(i, 1);
  }

  ngOnInit() {

  }


  assInfo = {};

  showInfo(id: string) {
    this.assInfo[id] = {
      show: true
    }
  }

  hideInfo(id: string) {
    this.assInfo[id] = {
      show: false
    }
  }

  shouldShow(id: string) {
    if (!this.assInfo[id]) return false;
    else return this.assInfo[id];
  }
  checkSelected(statement) {
    return statement.selected;
  }


  filtred() {

    if (this.step.round)
      return this.step.round.process.statements.filter(this.checkSelected);
    return null;
  }


  addSolution(item) {

    if (!item.solutions) item.solutions = [];

    item.solutions.push({
      text: "",
      category: "-1",
      parameters: this.factory.createDefaultParameters()
    }),

      this.step.saveTask();
  }


  canAddMoreMachineIdeas(item){
    let machineCount =item.solutions.filter(s => s.fromMachine).length; 
    return machineCount <2;
  }

  async ai(item) {

    console.log(item.problem);


    if (item.problem.length == 0) return;
    this.thinking = true;
    const createCompletionRequest = {
      model: "text-davinci-002",
      prompt: "Give one solution to problem: " + item.problem,
      temperature: 0.5,
      max_tokens: 120,
      top_p: 1.0,
      frequency_penalty: 1.5,
      presence_penalty: 0.0,
      n: 22

    };

    let r = await this.af.httpsCallable("solve")(
      {
        createCompletionRequest: createCompletionRequest
      }
    );

    let a = await r.toPromise();


    a.choices.forEach(c => {

      const result = c.text.trim();

      this.thinking = false;

      if (!item.solutions) item.solutions = [];

   

      if (this.canAddMoreMachineIdeas(item)) item.solutions.push({
        text: result,
        category: "-1",
        fromMachine: true,
        parameters: this.factory.createDefaultParameters()
      })
    });

  }
}
