import { Component, OnInit, Input } from '@angular/core';
import { StepService } from '../step.service';

@Component({
  selector: 'app-step-progress',
  templateUrl: './step-progress.component.html',
  styleUrls: ['./step-progress.component.css']
})
export class StepProgressComponent implements OnInit {


@Input()
individual:boolean;

@Input()
phase:number;

  constructor(public step:StepService) { }

  ngOnInit() {
  }

}
