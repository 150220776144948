import { Pipe, PipeTransform } from "@angular/core";
import { isNumber } from "util";

@Pipe({
  name: "ratingAvg"
})
export class RatingAvgPipe implements PipeTransform {
  transform(value: any, trigger: number): any {

    if(!value) value=[];
    let total = 0;
    let count = 0;
    value.forEach(ass => {
      let value = parseFloat(ass.rating);
   
      if (!isNaN(value) && value>0) {
        total += value;
        count++;
      }

    });
   
    if (count === 0) return 0;
    return total / count;
  }
}
