import { Component, OnInit } from '@angular/core';

import { isString } from 'util';
import { ScoreService } from '../score.service';

@Component({
  selector: 'app-xls-parse',
  templateUrl: './xls-parse.component.html',
  styleUrls: ['./xls-parse.component.css']
})
export class XlsParseComponent implements OnInit {

  // tickets: XLSX.WorkSheet;
  // header: any[];




  constructor(public score: ScoreService) { }

  ngOnInit() {
  }

  onFileChange(evt: any) {
  
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      this.score.handleFileNoProcess(e);
    };

    reader.readAsBinaryString(target.files[0]);
  }


}
