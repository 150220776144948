import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";

import { ActivatedRoute, Router } from "@angular/router";
import { AngularFirestore } from "@angular/fire/firestore";
import { Actor, Idea } from "../../round";
import { DataService } from "../../data.service";
import { DummyService } from "../../dummy.service";
import { PlatformLocation } from "@angular/common";
import { StepService } from "src/app/step.service";
import * as firebase from "firebase";
import { ModalService } from "src/app/modal.service";

@Component({
  selector: "app-actor-ass",
  templateUrl: "./actor-ass.component.html",
  styleUrls: ["./actor-ass.component.css"]
})
export class ActorAssComponent implements OnInit {

  assDone = false;

  statementAssessments: any[] = [];
  //psPairs: any[] = [];

  ratings = ["", 1, 2, 3, 4];

  showModal: boolean = false;

  //actor: Actor;
  changeTrigger: number; //todo jävla fullösning

  actor: Actor;

  statementPairs: any[];
  processes: any[];
  info: string;
  round: any;
  statementAsses: any[];
  finihed: boolean;
  finished: boolean;
  constructor(
    private pl: PlatformLocation,
    private data: DataService,
    private dummy: DummyService,
    private route: ActivatedRoute,
    private afs: AngularFirestore,
    private router: Router,
    private step: StepService,
    private modal: ModalService
  ) {
    step.setStep('1a');
    // if (data.currentRound === undefined) data.currentRound = data.rounds[0]; //todo navigera
  }



  setChangedScore(item, score, statement) {

    item.rating = score;

  }

  public ngOnDestroy() {
    this.step.setStep('-1');
  }

  ngOnInit() {


    this.route.paramMap.subscribe(params => {
      if (params.has("link")) {
        let link = params.get("link");

        let collection = this.afs.collection("actors", ref => {
          return ref.where("link", "==", link).limit(1);
        });

        let a = collection.valueChanges().subscribe(result => {
          if (result.length === 0) this.router.navigateByUrl("notfound");
          else {
            let actor = result[0] as any;

            this.step.loadActorFiles(actor.roundId, actor.id);

            //console.log("actor!", actor)
            this.step.setRoundName(actor.roundName);
            this.afs
              .collection("nrounds")
              .doc(actor.roundId)
              .get()
              .toPromise()
              .then(r => {
                this.round = r.data();

                this.step.round = this.round;

                let p = this.round.process;

                //   this.round.processes.forEach(p => {

                //todo sort statements
                //console.log("yo", p.statements)



                p.statements.forEach(statement => {
                  statement.pairs = [];

                  //creating empty assessments
                  statement.assessment = {
                    id: this.afs.createId()
                  };
                });
                //        });


                this.step.setProcessName(this.round.process.name);
                //                this.step.setProcessName(this.round.processes[0].name);




                this.afs
                  .collection("statementAsses")
                  .ref.where("actorId", "==", this.actor.id)
                  .get()
                  .then(a => {
                    this.statementAsses = [];

                    a.docs.forEach(doc => {
                      this.statementAsses.push(doc.data());
                    });

                    this.statementAsses.forEach(ass => {
                      let p = this.round.process;
                      //    this.round.processes.forEach(p => {
                      p.statements.forEach(statement => {
                        if (ass.statementId === statement.id) {
                          statement.assessment = ass;


                        }
                      });
                    });
                    //   });
                  });


              });

            this.afs
              .collection("nrounds")
              .doc(actor.roundId)
              .collection("processes")
              .get()
              .toPromise()
              .then(r => {
                this.processes = r.docs.map(p => {
                  return p.data();
                });

                r.docs.forEach(p => {
                  let process = p.data();
                });
              });

            this.actor = actor;

            let pairP = this.afs
              .collection("statementPairs")
              .ref.where("actorId", "==", this.actor.id)
              .get()
              .then(a => {
                this.statementPairs = [];

                a.docs.forEach(doc => {
                  this.statementPairs.push(doc.data());
                });

                this.statementPairs.forEach(pair => {
                  this.round.processes.forEach(p => {
                    p.statements.forEach(statement => {
                      if (!statement.pairs) statement.pairs = [];

                      if (pair.statementId === statement.id) {
                        statement.pairs.push(pair);
                      }
                    });
                  });
                });
              });

            //      let assP = this.afs

          }
        });
      }
    });
  }



  roughSizeOfObject(object) {
    var objectList = [];
    var stack = [object];
    var bytes = 0;

    while (stack.length) {
      var value = stack.pop();

      if (typeof value === "boolean") {
        bytes += 4;
      } else if (typeof value === "string") {
        bytes += value.length * 2;
      } else if (typeof value === "number") {
        bytes += 8;
      } else if (
        typeof value === "object" &&
        objectList.indexOf(value) === -1
      ) {
        objectList.push(value);

        for (var i in value) {
          stack.push(value[i]);
        }
      }
    }
    return bytes;
  }

  @ViewChild('thanksModal') myModal: ElementRef;


  onn() {

  }

  finish() {

    console.log("finish")

    this.assDone = true;
    this.finished = true;

this.save2();

    // this.myModal.nativeElement.click();

    // this.modal.showModal();


    // //console.log("Finish");

    // //console.log(this.round);
    // //console.log(this.actor)

    // var washingtonRef = this.afs.collection("cities").doc("DC");

    // // Atomically add a new region to the "regions" array field.
    // washingtonRef.update({
    //     regions: firebase.firestore.FieldValue.arrayUnion("greater_virginia")
    // });


    let r = this.afs.collection("nrounds").doc(this.round.id);


    r.update({
      actors: firebase.firestore.FieldValue.arrayRemove(this.actor)
    }).then(() => {

      this.actor.finished = true;

      r.update({
        actors: firebase.firestore.FieldValue.arrayUnion(this.actor)
      }).then(() => {


        // if (confirm("Close Window?")) {
        //   close();
        // }


      });
    });

  }

  save2() {
    let promises = [];
    this.info = "Saving...";

    //console.log('actor', this.actor);
    //console.log('round', this.round);

    let p = this.round.process;
    //   this.round.processes.forEach(p => {
    p.statements.forEach(statement => {
      let ass = statement.assessment;
      //console.log("ass", ass)
      ass.actorId = this.actor.id;
      ass.statementId = statement.id;
      ass.roundId = this.round.id;
      ass.name = this.actor.name;
      ass.role = this.actor.role;

      if (!ass.role) ass.role = ''; //todo fixa


      // statement.humans["foooo"]="baaar";

      // this.afs.collection("nrounds").doc(this.round.id).update(r=>r.process.statements.)

      promises.push(
        this.afs
          .collection("statementAsses")
          .doc(ass.id)
          .set(ass)
      );
    });
    // });

    Promise.all(promises).then(done => {
      //console.log("Saved " + promises.length + " things");

      //  this.info = "Done saving.";
      this.info = ""
      // setTimeout(() => {
      //   this.info = "";
      // }, 3000);
    });
  }

  save() {
    let id = this.actor.id;


    let asses = [];

    this.actor.processAssessments.forEach(pa => {
      pa.statementAssessments.forEach(sa => {
        //console.log("sa", sa);

        let statementAssessment: any = {};
        statementAssessment.sa = sa;
        statementAssessment.roundid = this.actor.roundId;
        statementAssessment.actorId = this.actor.id;
        statementAssessment.statementId = sa.statementId;
        statementAssessment.actor_statement =
          this.actor.id + "_" + sa.statementId;
        statementAssessment.id = this.actor.id + "_" + sa.statementId;

        asses.push(statementAssessment);
      });
    });

    //console.log("asses", asses);

    asses.forEach(ass => {
      this.afs
        .collection("assessments")
        .doc(ass.id)
        .set(ass);
    });

    this.info = "Saving assessment...";
    setTimeout(() => {
      this.info = "";
    }, 3000);

    // var a= this.actor.currentProcess.statementAssessments;
    //  //console.log("saving ", this.actor);
  }
}
