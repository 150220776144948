import { Component, OnInit, Input } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { StepService } from '../step.service';

@Component({
  selector: 'app-step1c-expansion',
  templateUrl: './step1c-expansion.component.html',
  styleUrls: ['./step1c-expansion.component.css']
})
export class Step1cExpansionComponent implements OnInit {



  @Input()
  item;

  @Input()
  showGraph;

  datasetUrl:string;

  constructor(private step: StepService, private storage: AngularFireStorage) { }

  ngOnInit() {

    let ref = this.storage.ref('/xls/' + this.step.round.dataset);


    ref.getDownloadURL().subscribe(res => {
   //   console.log("ng", res)
      this.datasetUrl=res;
    })


  }

  random() {

    return Math.floor(Math.random() * 1000);
  }

  downloa22222d(file: string) {

    let ref = this.storage.ref('/xls/' + file);


    // ref.getDownloadURL().subscribe(res => {
    //   console.log("res", res)
    // })




    ref.getDownloadURL().toPromise()
    .then((url) => {
      // `url` is the download URL for 'images/stars.jpg'
  
      // This can be downloaded directly:
      console.log("got url",url)
      var xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        var blob = xhr.response;
      };
      xhr.open('GET', url);
      xhr.send();
  
      // Or inserted into an <img> element
      var img = document.getElementById('myimg');
      img.setAttribute('src', url);
    })
    .catch((error) => {
      // Handle any errors
    });











  }

}
