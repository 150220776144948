import { HttpClient, HttpHeaders } from '@angular/common/http';
import { identifierModuleUrl } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';

import { ActivatedRoute } from '@angular/router';
import { DatasetviewerService } from '../datasetviewer.service';
import { StepService } from '../step.service';


import { from, VirtualTimeScheduler } from 'rxjs';

@Component({
  selector: 'app-datasetviewer',
  templateUrl: './datasetviewer.component.html',
  styleUrls: ['./datasetviewer.component.css']
})
export class DatasetviewerComponent implements OnInit {
  datasetUrl: any;



  constructor(private http: HttpClient, public datasetviewer: DatasetviewerService, private step: StepService, private storage: AngularFireStorage, private route: ActivatedRoute, private afs: AngularFirestore) { }

  ngOnInit() {

    this.route.params.subscribe(r => {
     // console.log("params", r)
      if (r.id) {

        let round = this.afs.collection('nrounds').doc(r.id).get().subscribe(rond => {
       //   console.log("rounds", rond.data())

          if (rond.exists) {
            let ref = this.storage.ref('/xls/' + rond.data().dataset);


            ref.getDownloadURL().subscribe(res => {







              let result = from( // wrap the fetch in a from if you need an rxjs Observable
                fetch(
                  res
                  //   {
                  //   //  body: JSON.stringify({data:"apa"}),
                  //     headers: {
                  //       'Content-Type': 'application/json',
                  //     },
                  //  //  method: 'POST',
                  //     mode: 'no-cors'
                  //   }
                )
              );


              result.subscribe(f => {
                // console.log("fetched", f.status)
                // console.log("fetched", f.statusText)
                // console.log("fetched", f.ok)
                //   console.log("fetched blob",f.blob())


                f.blob().then(b => {
                //  console.log("f blob", b)
                  
this.datasetviewer.urlData(b);

                  // b.arrayBuffer().then(ab => {
                  //   console.log("Length", ab)
                 
                  // })

                })
              })











              //    console.log("url", res)
              this.datasetUrl = res;
              // this.download(res);
            })
          }

        })


      }
    })

    console.log("step", this.step)
    // let ref = this.storage.ref('/xls/' + this.step.dataseturl);


    // ref.getDownloadURL().subscribe(res => {
    //   console.log("ng", res)
    //   this.datasetUrl=res;
    // })


  }
  download(res: any) {


    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'authkey',
        'userid': '1'
      })
    }

    console.log("Start downlad")
    let a = this.http.get(this.datasetUrl, { observe: 'response', responseType: 'blob' });

    a.subscribe(res => {
      console.log("Downloaded?", res)
      this.datasetviewer.handleFileNoProcess(res.body)
    })
  }




  onFileChange(evt: any) {

    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      this.datasetviewer.handleFileNoProcess(e);
    };

    reader.readAsBinaryString(target.files[0]);
  }
}
