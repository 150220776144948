import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'score'
})
export class ScorePipe implements PipeTransform {

  transform(value: any, args?: any): any {




let values=[];


if(value.commonHuman )values.push(value.commonHuman)
if(value.commonMachine )values.push(value.commonMachine)




    let total = 0;
    let count = 0;
    values.forEach(v => {
      let value =parseFloat(v);
   
     
      if (!isNaN(value) && value>0) {
        total += value;
        count++;
      }

    });


let avg:number=total/count;


//if(!value.score) value.score=avg;

    return avg;
  
  }

}
