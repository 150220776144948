import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterContentChecked,
  Input
} from "@angular/core";
import { Chart } from "chart.js";
import { BaseMachineData } from "../../round";
import { DummyService } from "../../dummy.service";

@Component({
  selector: "app-machine-data-view",
  templateUrl: "./machine-data-view.component.html",
  styleUrls: ["./machine-data-view.component.css"]
})
export class MachineDataViewComponent implements OnInit, AfterContentChecked {
  showTable: boolean = false;
  ngAfterContentChecked(): void {}

  @Input() data: any;
  @Input() type: any;

  chart: any;
  // data = {
  //   labels: ["a", "b", "c", "d"],
  //   rows: [[1, 2, 3, 50], [4, 5, 6, 64], [7, 8, 9, 72]]
  // };

  constructor(private dummy: DummyService) {
 //   //console.log("c-DATA", this.data);
  }

  ngOnInit() {
  //  //console.log("o-DATA", this.data);
  }

  @ViewChild("graphCanvas") graphCanvas: ElementRef;
  ngAfterViewInit() {
    //https://blog.angular-university.io/angular-debugging/
    setTimeout(() => {

     
// this.data={
//   description:'',
//   labels:['a','c','d'],
//   name:'',
//   rating:1,
//   viewType:'bar',
//   values:[1,2,3]
// }
 
//this.data.viewType='pie';

      //  let n = this.dummy.randomInt(1, 4);
   if(this.type) this.data.viewType =this.type;

      if (!this.data.viewType) this.data.viewType = "pie";

      switch (this.data.viewType) {
        case "bar":
          this.createBarChart();
          break;

        case "pie":
          this.createPie();
          //console.log("create pie");
          break;

        case "line":
          this.createLineChart();
          break;
        default:
          this.createTable();
          //console.log("Creating Table");
          break;
      }
    }, 1);
  }

  createTable(): any {
    //console.log("setting show table", this.showTable);
    this.showTable = true;
    //console.log("setted show table", this.showTable);
  }
  createLineChart(): any {
    this.chart = new Chart(this.graphCanvas.nativeElement, {
      responsive: true,
      maintainAspectRatio: false,
      type: "line",
      data: {
        labels: this.data.labels,
        datasets: [
          {
            data: this.data.values,
            borderColor: "#3cba9f",
            fill: false,
            yAxisID: "A",
            backgroundColor: [
              "#FF6384",
              "#63FF84",
              "#84FF63",
              "#8463FF",
              "#6384FF"
            ]
          },
          {
            data: [],
            borderColor: "#4286f4",
            fill: false,
            yAxisID: "B",

            hidden: true
          }
        ]
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              display: true,
              //  type: "time",
              time: {
                displayFormats: {
                  minute: "HH:mm",
                  hour: "HH:mm"
                }
              }
            }
          ],
          yAxes: [
            {
              id: "A",
              type: "linear",
              position: "left",
              ticks: {
                callback: function(value, index, values) {
                  return value; // + "°C";
                }
              }
            }
          ]
        }
      }
    });
  }
  updateChart(): any {
    this.chart.data.labels = [];
    this.chart.data.datasets[0].data = [];
    this.chart.data.datasets[1].data = [];

    let values = this.dummy.getArray(10, 1, 10);

    let count = 1;
    if (values) {
      values.forEach(temp => {
        let value = temp;

        this.chart.data.labels.push(count++);
        this.chart.data.datasets[0].data.push(value);
      });
    }

    this.chart.update();
  }

  createPie() {
    this.chart = new Chart(this.graphCanvas.nativeElement, {
      //   responsive:true,
      // maintainAspectRatio: false,
      type: "pie",
      data: {
        labels: this.data.labels,
        datasets: [
          {
            data: this.data.values,
            backgroundColor: [
               "#63FF84",
              "#FF6384",
             
              "#84FF63",
              "#8463FF",
              "#6384FF"
            ]
            // borderColor: "#3cba9f",
            // fill: false,
            //   yAxisID: "A"
          }
        ]
      },
      options: {
        legend: {
          display: true,
          position: "right"
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              //get the concerned dataset
              var dataset = data.datasets[tooltipItem.datasetIndex];
              //calculate the total of this data set
              var total = dataset.data.reduce(function(
                previousValue,
                currentValue,
                currentIndex,
                array
              ) {
                return previousValue + currentValue;
              });
              //get the current items value
              var currentValue = dataset.data[tooltipItem.index];
              //calculate the precentage based on the total and current item, also this does a rough rounding to give a whole number
              var percentage = Math.floor((currentValue / total) * 100 + 0.5);

              return currentValue + " (" + percentage + "%)";
            }
          }
        }
      }
    });
    //   this.chart.data.labels = [];
    //   this.chart.data.datasets[0].data = [];
    //   this.chart.data.datasets[1].data = [];

    //   let values=this.dummy.getArray(5,1,10);

    //   let labels=this.dummy.getWordArray(5);
    //   let count = 1;

    // for (let i = 0; i < values.length; i++) {
    //   const  v  = values[i];
    //   this.chart.data.labels.push(labels[i]);
    //   this.chart.data.datasets[0].data.push(v);

    // }

    // values.forEach(temp => {
    //   let value = temp;

    //   this.chart.data.labels.push(count++);
    //   this.chart.data.datasets[0].data.push(value);

    // });

    this.chart.update();
  }
  createBarChart() {
    this.chart = new Chart(this.graphCanvas.nativeElement, {
      responsive: true,
      maintainAspectRatio: false,
      type: "bar",
      data: {
        labels: this.data.labels,
        datasets: [
          {
            data: this.data.values,
            borderColor: "#3cba9f",
            fill: false,
            yAxisID: "A",
            backgroundColor: [
              "#FF6384",
              "#63FF84",
              "#84FF63",
              "#8463FF",
              "#6384FF"
            ]
          },
          {
            data: [],
            borderColor: "#4286f4",
            fill: false,
            yAxisID: "B",

            hidden: true
          }
        ]
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              display: true
              //  type: "time",
              // time: {
              //   displayFormats: {
              //     minute: "HH:mm",
              //     hour: "HH:mm"
              //   }
              // }
            }
          ],
          yAxes: [
            {
              id: "A",
              // type: "linear",
              // position: "left",
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      }
    });

    // this.updateChart();
  }
}
