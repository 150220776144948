import { Component, OnInit, Input } from '@angular/core';
import { StepService } from '../step.service';
import { fade, slideUp } from '../animations/animations';
import { retry } from 'rxjs/operators';

@Component({
  selector: 'app-hdms-scoring',
  templateUrl: './hdms-scoring.component.html',
  styleUrls: ['./hdms-scoring.component.css'],
  animations: [
    fade,
    slideUp
  ]
})
export class HdmsScoringComponent implements OnInit {
  ratings = ["", 1, 2, 3, 4];

  @Input() currentPEval;

  constructor(public step: StepService) { }


  changeTrigger: number; //todo jävla fullösning
  change(statement) {
    this.changeTrigger = new Date().getMilliseconds();


    if (!statement.score)

      statement.score = this.calcScore(statement);



  }

  setPrio(item, prio) {

    item.prio = prio;

  }

  getScore(ass) {
    //  //console.log("ass",ass.rating, ass.changedrating,ass);


    if (!isNaN(ass.changedrating)) return ass.changedrating;
    else if (!isNaN(ass.rating)) return ass.rating;

    return null;
  }
  //todo service som pipe kan använda
  calcScore(value: any): any {

    //console.log("calc score")

    let values = [];


    if (value.commonHuman) values.push(value.commonHuman)
    if (value.commonMachine) values.push(value.commonMachine)



    let total = 0;
    let count = 0;
    values.forEach(v => {
      let value = parseFloat(v);


      if (!isNaN(value) && value > 0) {
        total += value;
        count++;
      }

    });


    let avg: number = total / count;



    return avg;
  }



  showScore(item) {

    alert(item.commonMachineDesc)

  }


  ngOnInit() {
    if (this.currentPEval) this.currentPEval.statements.forEach(statement => {
      statement.scoringExpanded = false;

      // //console.log(statement);
    });
  }


  countFiles(sid) {
    let sum = 0;
    this.step.docs.forEach(s => {
      if (s.statement === sid) sum++;
    });
    return sum;   
  }

}
