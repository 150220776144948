import { Component, OnInit, Input } from '@angular/core';
import { StepService } from '../step.service';
import { UploadFileService } from '../upload-file.service';
import { AngularFireStorageReference, AngularFireUploadTask, AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { saveAs } from 'file-saver';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-upload-document-modal',
  templateUrl: './upload-document-modal.component.html',
  styleUrls: ['./upload-document-modal.component.css']
})
export class UploadDocumentModalComponent implements OnInit {

  // @Input() showAdd: boolean = true;
  // @Input() filename: string;
  // @Input() description: string;

  // @Input() statement: any = "test";
  // @Input() actor: any;
  // @Input() step: any;
  // @Input() roundid: any;

  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  uploadProgress: Observable<number>;
  downloadURL: string;

  uploadstatus = "Choose and upload file";


  uploadSelected = false;
  docSelected = false;


  saveOk = false;
  uploadedFileName: any;

  uploading = false;

selectedDoc;

  constructor(public stepService: StepService, public upload: UploadFileService, private afStorage: AngularFireStorage, private http: HttpClient) { }

  ngOnInit() {
  }

  onFileChange(evt: any) {
    if (evt.target.files.length !== 1) {
      alert("You can only add one file at the time.");
      return;
    }
    //console.log(evt);
    this.upload.filename = evt.target.files[0].name;
    // const target: DataTransfer = <DataTransfer>(evt.target);
    // if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    // const reader: FileReader = new FileReader();
    // reader.onload = (e: any) => {
    //   //todo lägg i score servive
    //   try {
    //     this.score.handleFile(e, this.selectedProcesses[0]);
    //   } catch (err) {
    //     this.score.scoringResultInfo = "Analysing failed. Please ensure that selected document contains correct data."
    //     this.score.scoringResult=-1;
    //   }

    // };

    // reader.readAsBinaryString(target.files[0]);
  }


  saveDocument() {
    //console.log("apa")
    //console.log("1111111yo2222!", this.upload.filename, this.upload.description);
    //console.log("roundid", this.upload.roundid)

    // if (!this.upload.filename) {
    //   //console.log("no file!")
    //   return;
    // }
    //console.log("aaa32")
    if (!this.upload.description) this.upload.description = "";
    // if (!this.upload.statement.docs) this.upload.statement.docs = [];
    // this.upload.statement.docs.push({
    //   filename: this.upload.filename,
    //   description: this.upload.description
    // })
    //console.log("222")
    let doc: any = {
      filename: this.upload.filename,
      description: this.upload.description,
      statement: this.upload.statement

    }
    if (this.upload.actor) doc.actor = this.upload.actor;

    if (this.uploadSelected) {
      doc.fileUrl = this.downloadURL;
      doc.filename = this.uploadedFileName;
    }else{

      doc.fileUrl = this.selectedDoc.fileUrl;
      doc.filename = this.selectedDoc.filename;
    }

    //console.log("add doc", doc);
    if (!this.upload.createtask) this.stepService.addDoc(this.upload.roundid, doc);

    else {
      if (!this.upload.round.docs) this.upload.round.docs = [];

      delete doc.statement;
      this.upload.round.docs.push(doc);
    }

  }

  uploadDoc = (event) => {
    this.saveOk = false;
    //console.log("Upload!", event)

    let file = event.target.files[0];

    this.uploading = true;

    this.uploadedFileName = file.name;

    this.uploadstatus = "Uploading " + file.name + "...";
    this.uploadSelected = true;
    this.docSelected = false;
    // create a random id
    const randomId = Math.random().toString(36).substring(2) + "." + this.uploadedFileName.split('.').pop();

    //console.log("random name: " + randomId);
    // create a reference to the storage bucket location
    this.ref = this.afStorage.ref('/documents/' + randomId);
    // the put method creates an AngularFireUploadTask
    // and kicks off the upload

    var metadata = {

      customMetadata: {

        filename: file.name
      }
    };


    this.task = this.ref.put(file, metadata);

    // AngularFireUploadTask provides observable
    // to get uploadProgress value
    this.uploadProgress = this.task.percentageChanges();




    this.task.snapshotChanges().pipe(
      finalize(() => {
        this.uploading = false;

        //    this.downloadURL = this.ref.getDownloadURL()
        this.ref.getDownloadURL().subscribe(f => {
          this.uploadstatus = "File uploaded."
          setTimeout(() => {
            this.uploadstatus = file.name;
            this.uploadSelected = true;
            this.saveOk = true;
          }, 1000)
          this.downloadURL = f;
        })

      })
    )
      .subscribe();

  }

  cancelUpload() {
    this.task.cancel();
    this.uploading = false;
    this.uploadstatus = "Upload cancelled"
  }

  onChangeSelect() {
    this.uploadSelected = false;
    this.docSelected = true;
    this.saveOk = true;
  }
  //   public getFile(path: string):Observable<Blob>{
  //     let options = new RequestOptions({responseType: ResponseContentType.Blob});

  //     return this.http.get(path, options)
  //             .map((response: Response) => <Blob>response.blob())              
  //             .catch(this.handleError);
  // }

  download() {

    // this.http.get(this.downloadURL).subscribe(f=>{
    //    saveAs.saveAs( f, this.uploadedFileName);
    // })
    saveAs.saveAs(this.downloadURL, this.uploadedFileName);
    //console.log("save as? " + this.uploadedFileName)

  }

}
