import { Component, OnInit } from "@angular/core";
import {
  AngularFirestoreCollection,
  AngularFirestore
} from "@angular/fire/firestore";
import { Round, ProcessEval2 } from "../../round";
import { Observable } from "rxjs";
import { DataService } from "../../data.service";
import { Router } from "@angular/router";
import { AuthService } from "../../auth.service";
import { map } from "rxjs/operators";
import { StepService } from "src/app/step.service";
import { st } from "@angular/core/src/render3";

@Component({
  selector: "app-rounds",
  templateUrl: "./rounds.component.html",
  styleUrls: ["./rounds.component.css"]
})
export class RoundsComponent implements OnInit {
  // rounds: any[] = [];
  roundsCollection: AngularFirestoreCollection<Round>;
  rounds: Observable<Round[]>;
  domainUrl: string;
  nroundsCollection: AngularFirestoreCollection<{}>;
  nrounds: Observable<
    {
      selectedService: any;
      serviceName: string;
      created: any;
      lastUpdate: any;
      description: string;
      actors: any[];
      machines: any[];
      processes: any[];
      name: string;
      processAss2: ProcessEval2[];
      id: string;
    }[]
  >;
  currentRound: string;
  constructor(
    public data: DataService,
    private router: Router,
    private auth: AuthService,
    private afs: AngularFirestore,
    private step:StepService
  ) {
    //todo private
step.setStep("0");

    this.afs.collection('users').doc(this.auth.userid).get().subscribe((r) => {
      //console.log(r.data)
    })



    this.roundsCollection = afs.collection("rounds");

    this.rounds = this.roundsCollection.snapshotChanges().pipe(
      map(action =>
        action.map(a => {
          const data = a.payload.doc.data() as Round;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );

    this.nroundsCollection = afs.collection("nrounds", ref => ref.orderBy('created', 'desc'));


    //let a=this.nroundsCollection.valueChanges()

    this.nrounds = this.nroundsCollection.snapshotChanges().pipe(
      map(action =>
        action.map(a => {
          const data = a.payload.doc.data() as Round;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );
  }

  signin() {
    this.auth.login("", "");
  }
  ngOnInit(): void { }

  // createDummyRound(): any {
  //   let round = {
  //     id: this.dummy.comment(1),

  //     name: this.dummy.comment(1),
  //     created: new Date()
  //   };
  //   return round;
  // }

  create() {
    this.data.editRound(null);
    this.router.navigateByUrl("createround");
    //   this.rounds.push(this.createDummyRound());
  }

  edit(round: Round) {
    this.data.editRound(round);
    this.router.navigate(["editround"]);
  }

  assesRound(round: Round) {
    this.data.setCurrentRound(round);
    this.router.navigate(["assess"]);
  }

  delete(round: Round) {
    if (confirm("Are you sure you want to delete " + round.name + "? This cannot be undone.")) {


      this.afs.collection('nrounds').doc(round.id).delete();

      //this.data.deleteRound(round);
    }
  }


  setCurrent(round: Round) {

    this.afs.collection('users').doc(this.auth.userid).update({ currentRound: round.id })

    this.auth.currentRound = round.id;
  }
}
