import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { StepService } from '../step.service';

@Component({
  selector: 'app-small-header',
  templateUrl: './small-header.component.html',
  styleUrls: ['./small-header.component.css']
})
export class SmallHeaderComponent implements OnInit {


  constructor(public auth: AuthService,public step: StepService) {

  }

  ngOnInit() {}

  login() {
    this.auth.login("","");
  }
  logout() {
    this.auth.logout();
  }

}
