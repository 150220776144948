import { Component, OnInit, Input } from '@angular/core';
import { FactoryService } from '../factory.service';
import { StepService } from '../step.service';

@Component({
  selector: 'app-valuation',
  templateUrl: './valuation.component.html',
  styleUrls: ['./valuation.component.css']
})
export class ValuationComponent implements OnInit {


  @Input()
  currentPEval;


  currentStatement;

  selectedIdea;

  idea:any = {

  

  }


  constructor(private factory:FactoryService, private step:StepService) {

      this.idea.parameters=factory.createDefaultParameters();

      //console.log("idea",this.idea )

      this.currentStatement=this.step.round.process.statements.filter(this.checkSelected)[0];
   }

  ngOnInit() {

  
  }


  checkSelected(statement) {
    return statement.selected;
  }


  filtred(){

    if(this.step.round) 
    return  this.step.round.process.statements.filter(this.checkSelected);
    return null;
  }

  selectIdea(s){
console.log(s)

    this.selectedIdea=s;

  }

}
