import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../auth.service";

@Component({
  selector: "app-service",
  templateUrl: "./service.component.html",
  styleUrls: ["./service.component.css"]
})
export class ServiceComponent implements OnInit {
  id: string;
  service: any;

  create=false;
  constructor(private afs: AngularFirestore, private route: ActivatedRoute,private auth:AuthService,private router:Router) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      //let item=new Item();
      if (params.has("id")) {
        this.id = params.get("id");
     
     

        if (this.id.toLowerCase() == "new") {
          this.id = this.afs.createId();
          this.service={};
          this.service.userid=this.auth.userid;
          this.create=true;
        }
        else {
          this.afs
            .collection("services")
            .doc(this.id)
            .get()
            .subscribe(o => {
              this.service = o.data();
              //console.log("hittade service", this.service);
            });
        }
      }
    });
  }

  save() {

    if(this.create) {
      this.afs
      .collection("services")
      .doc(this.id)
      .set(this.service);
    }else
    this.afs
      .collection("services")
      .doc(this.id)
      .update(this.service);



      this.router.navigateByUrl("/services")
  }

cancel(){
  this.router.navigateByUrl("/services")
}
}
