import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-select-score',
  templateUrl: './select-score.component.html',
  styleUrls: ['./select-score.component.css']
})
export class SelectScoreComponent implements OnInit {

  @Input()
  item;

  @Input()
  rating

  @Input()
  disabled: boolean = false;

  @Input()
  showColor: boolean = true;


@Input()
score:number;

@Output()
scoreChange=new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  setChangedScore( score) {
  

    score=score;
    this.scoreChange.emit(score);

    console.log(score)
  
  }
  // setChangedScore(item, score, statement) {
  //   item.rating = score;

  //   console.log("rating",this.rating);
  //   console.log("item",this.item);
  // }


}
