import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'scorecolor'
})
export class ScorecolorPipe implements PipeTransform {

  transform(value: any, args?: any): any {

//console.log("score color!")

    let color="";

    if(!value) return color;


    let score=parseFloat(value);

  color="green";


    if(score<4) color="yellow";
    if(score<3) color="orange";
    if(score<2) color="red";


   
    return color;
  }

}
