import { Component, OnInit, ViewChildren, QueryList, Input } from "@angular/core";
import { FormsModule } from '@angular/forms';


import {  AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { DummyService } from "../../dummy.service";

@Component({
  selector: "app-test-view",
  templateUrl: "./test-view.component.html",
  styleUrls: ["./test-view.component.css"]
})
export class TestViewComponent implements OnInit {
  statements: any[] = [];

ratings=[
  "",1,2,3,4,5
];

changeTrigger:number; //todo jävla fullösning

change(){
  this.changeTrigger=new Date().getMilliseconds();
}


  constructor(private dummy:DummyService) {
    for (let index = 0; index < 5; index++) {
      this.statements.push(this.createStatement());
    }

    this.statements[0].expanded = true;
  }

  ngOnInit() {


  }


get humanAvg(){
  return -1;


}



  createStatement() {


    let s = {
      expanded: false,
      Text: "The service provider and the customer have jointly agreed upon a documented strategy for how to manage events.",
      Human: 4,
      Machine: 1,
      Mutual: 2.5,
      Comment: this.dummy.comment(5),
     // IdeasSummary: "idea 1, idea 2...",
      machineAss: [],

      humanAss: [
        {
          name: "Actor 1",
          rating: Math.round(Math.random()*5),
          comment: this.dummy.comment(5),
        },

        {
          name: "Actor 2",
          rating: Math.round(Math.random()*5),
          comment: this.dummy.comment(5),
        },

        {
          name: "Actor 3",
          rating: Math.round(Math.random()*5),
          comment: this.dummy.comment(5),
        }
      ],

      ideas: [],
      humanAvg: function() {
        //console.log("getting avg")
        return "apa";
    }
    };

    s.machineAss.push(this.createMachineAss());
    s.machineAss.push(this.createMachineAss());
    s.machineAss.push(this.createMachineAss());
    s.ideas.push(this.createIdea());
    s.ideas.push(this.createIdea());
    s.ideas.push(this.createIdea());
    return s;
  }

  createIdea() {
    let d = new Date();

    let i = {
      id: 1,
      text: this.dummy.comment(5),
      source: "fgfret42",
      value: Math.round(Math.random()*5),
      comment:this.dummy.comment(5),
      prio: true,
      created: d.getTime()
    };
    return i;
  }

  createHumanAss() {
    let d = new Date();
    let ass = {
      name: "Actor X",
      rating:  Math.round(Math.random()*5),
      comment: this.dummy.comment(5),
      created: d.getTime()
    };
    return ass;
  }
  createMachineAss() {
    let d = new Date();
    let pseudoid = Math.round(Math.random() * 100);
    let ass = {
      rating:  Math.round(Math.random()*5),
      comment: this.dummy.comment(5),
      created: d.getTime(),
      expanded: false
    };
    return ass;
  }

  expand(i) {
    // this.statements[i].expanded=!this.statements[i]; ???
    if (this.statements[i].expanded) this.statements[i].expanded = false;
    else this.statements[i].expanded = true;
  }

  collapseAll() {
    this.statements.forEach(element => {
      element.expanded = false;
    });
  }

  addIdea(statement) {
    statement.ideas.push(this.createIdea());
  }

  removeIdea(statement, i) {
    statement.ideas.splice(i, 1);
  }

  addHumanAss(statement) {
    statement.humanAss.push(this.createHumanAss());
  }

  removeHumasAss(statement, i) {
    //   statement.ideas.splice(i, 1);
  }

  addMachineAss(statement) {
    //console.log("adding machine");
    statement.machineAss.push(this.createMachineAss());
  }

  @ViewChildren('cmp') components:QueryList<any>;

focusIdea(statement,idea){
  statement.expanded=true;

  //todo
 // //console.log(this.components);
  //this.components.first.nativeElement.focus();


}

  showjson = false;
  toggleJSON() {
    this.showjson = !this.showjson;
    // if (this.showjson) this.statements[i].expanded = false;
    // else this.statements[i].expanded = true;
  }
}
