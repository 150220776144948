import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../auth.service";



@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"]
})
export class HeaderComponent implements OnInit {
  userid = "que?";


  constructor(public auth: AuthService) {

  }

  ngOnInit() {}

  login() {
    this.auth.login("","");
  }
  logout() {
    this.auth.logout();
  }
}
