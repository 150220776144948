import { Component, OnInit } from "@angular/core";
import { Process } from "../../../round";
import { DummyService } from "../../../dummy.service";
import { DataService } from "../../../data.service";

declare var require: any;

@Component({
  selector: "app-create-from-file",
  templateUrl: "./create-from-file.component.html",
  styleUrls: ["./create-from-file.component.css"]
})
export class CreateFromFileComponent implements OnInit {
  rawText: string = '{"name":"namnet"}';
  error: string = "";
  generatedProcess: Process;
  info: string;
  allOk: boolean = false;
  constructor(private dummy: DummyService, private data: DataService) {
    this.rawText = dummy.getPizza();
  }

  ngOnInit() {}

  processText() {
    //console.log(this.rawText);

    try {
      let obj = JSON.parse(this.rawText) as Process;
      this.generatedProcess = obj;

      this.allOk = true;
      this.info = "Lyckades konvertera JSON till process!";
    } catch (error) {
      this.error = error;
      this.allOk = false;
      this.info = error;
    }
  }

  saveProcess() {
    this.data.addProces(this.generatedProcess);
  }
}
