import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";
import { StepService } from "../step.service";

@Component({
  selector: "app-visualisation",
  templateUrl: "./visualisation.component.html",
  styleUrls: ["./visualisation.component.css"],
})
export class VisualisationComponent implements OnInit {

  currentStatement;
  currentIdea;

  checkSelected(statement) {
    return statement.selected;
  }
  @ViewChild("showdModalButton") myModal: ElementRef;


  constructor(public step: StepService) {

    // let s = this.step.round.process.statements;
    let s = this.step.round.process.statements.filter(this.checkSelected);

    if (s != undefined) {
      //  console.log("s",s)
      s.forEach(x => {
        this.rankSolutions(x);
      })
      this.currentStatement = s[0];
      console.log("setting current", this.currentStatement)
    }
  }

  clickIdea(idea) {
    this.currentIdea = idea;
    this.myModal.nativeElement.click();
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  ngOnInit() { }

  filtred() {
    if (this.step.round)
      return this.step.round.process.statements.filter(this.checkSelected);
    return null;
  }

  calcAvg(p1: any): any {
    let avgX =
      p1.reduce((acc, curr) => {
        return acc + curr.value;
        //return acc + curr.value * curr.weight;
      }, 0) / p1.length;

    return avgX;
  }






  //todo chart använder samma. använd en helper eller nåt
  getAvgFromFactorArrayOnAxis(factors: any[], axis: string) {
    return this.getAvgValueFromFactorArray(factors.filter(cur => cur.axis == axis));
  }


  getSumOfAllFactor(factors: any[]) {

    let sum = 0;
    //  console.log("factors",factors)

    factors.forEach(f => {
      sum += f.value;
    })
    //  console.log("factors sum",sum)

    return sum;
  }

  //todo chart använder samma. använd en helper eller nåt
  getAvgValueFromFactorArray(arr: any[]) {
    return arr.reduce((acc, curr) => {
      return acc + curr.value;
    }, 0) / arr.length;

  }

  saveIdea(idea) {

    //  idea.preferred=true;

    this.step.saveTask();

  }

  removePreferred(idea) {
    idea.preferred = false;
    this.step.saveTask();
  }

  setPreffered(r) {
    this.currentStatement.solutions.forEach(element => {
      console.log(element);
      element.preferred = false;
    });
    r.preferred = true;
  }

  rankSolutions(x: any) {

    let highest=0;
    x.solutions.forEach(s => {
      s.sum = this.getSumOfAllFactor(s.parameters.evalFactors)

      if(highest<s.sum) highest=s.sum;
    });

    x.solutions.forEach(s => {
    
      if(highest==s.sum) s.machinePreffered=true;
      else s.machinePreffered=false;
    });


  }


}
