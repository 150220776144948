import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
 
  show:boolean=false;
 
  showModal() {
    
  }

  constructor() { }
}
