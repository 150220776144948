import { Component, OnInit, Input } from '@angular/core';
import { FactoryService } from '../factory.service';

@Component({
  selector: 'app-para-table',
  templateUrl: './para-table.component.html',
  styleUrls: ['./para-table.component.css']
})
export class ParaTableComponent implements OnInit {


  @Input()
  paraname:string;
  
  @Input()
  paras: [any];




  constructor(private factory: FactoryService) { }

  ngOnInit() {
  }

  addPara() {
    this.paras.push(this.factory.createParameter("","","feasibility"));
  }


  removePara(index) {
    if (confirm("Do you want to remove the parameter?")) {

      this.paras.splice(index, 1);

    }

  }

}
