import { Component, OnInit, Input } from '@angular/core';
import { TouchSequence } from 'selenium-webdriver';
import { StepService } from '../step.service';
import { filter, map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { UploadFileService } from '../upload-file.service';

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.css']
})
export class UploadDocumentComponent implements OnInit {


  @Input() showAdd: boolean = true;
  @Input() showUploader: boolean = true;
  @Input() filename: string;
  @Input() description: string;

  @Input() statement: any = "test";
  @Input() actor: any;
  @Input() step: any;
  @Input() roundid: any;
  @Input() round: any;
  @Input() hideEmpty: boolean;
  @Input() uploadedBy: string;




  @Input() createtask: boolean = false;



  constructor(private stepService: StepService, private afs: AngularFirestore, private upload: UploadFileService) { }

  ngOnInit() {
  }



  checkSelected(statement) {
    return statement.selected;
  }

  hidden() {

    let a = this.filtred();
    //console.log("filtredd", a)

    if (!a) return false;

    return (a.length == 0 && this.hideEmpty)
  }

  filtred() {

    if (this.createtask) {

      return this.round.docs;
    }


    let actor = this.actor;
    if (!actor) { //hämtar doc som är uppladdade vid gemensam genomgång
      //  //console.log("no actor", this.statement.id)
      return this.stepService.docs.filter(r => !r.actor && r.statement === this.statement.id);


      // return this.stepService.docs.pipe(

      //   map((arr) =>{
      //      arr.filter(r => r.filename==="boot.config")
      //   }

      //   )
      // );
    }


    return this.stepService.docs.filter(r => r.actor === actor && r.statement === this.statement.id);
    // return  this.afs
    //     .collection("nrounds")
    //     .doc(this.roundid).collection("files", f=>f.where('actor','==',actor))
    //     .valueChanges();

    // return this.stepService.docs;

    // return this.stepService.docs.pipe(

    //   map(arr =>
    //     arr.filter(r => r.actor === actor)
    //   )
    // );

  }

  populateModal() {
    //console.log("yo!", this.statement)
    this.upload.description = "";

    if (this.createtask) {

      this.upload.createtask = true;
      this.upload.round = this.round;
      return;
    }

    this.upload.createtask = false;
    this.upload.statement = this.statement.id;
    this.upload.roundid = this.roundid;
    this.upload.actor = this.actor;
  }

  clickDoc(doc) {
    //console.log(doc);
  }

  delete(doc) {
    if (confirm("Do you want to remove the document?"))
      this.stepService.removeDoc(this.roundid, doc);
  }
}
