import {
  Component,
  OnInit,
  AfterContentChecked,
  Input,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { DummyService } from "../dummy.service";
import { Chart } from "chart.js";
// import { VirtualTimeScheduler } from "rxjs";

//import ChartDataLabels from 'chartjs-plugin-datalabels';
//import * as ChartDataLabels from 'chartjs-plugin-datalabels';
//import  'chartjs-plugin-datalabels';
//import * as ChartDataLabels  from 'chartjs-plugin-datalabels';
//import { default as ChartDataLabels  } from 'chartjs-plugin-datalabels';

// import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: "app-idea-chart",
  templateUrl: "./idea-chart.component.html",
  styleUrls: ["./idea-chart.component.css"],
})
export class IdeaChartComponent implements OnInit, AfterContentChecked {
  showTable: boolean = false;
  ngAfterContentChecked(): void { }
  ngOnChanges(changes: any) {
    console.log("CHANGES!");
    this.setData();
  }
  
  setData() {
    this.updatechart2();
  }
  @Input() data: any;
  @Input() type: any;
  @Input() graphtitle: any;
  @Input() ideas: any;
  @Input() solutions: any;
  @Input() graphtype: any;

  chart: any;

  options = {
    showTooltips: true,

    onAnimationComplete: function () {
      this.showTooltip(this.datasets[0].points, true);
    },
    onClick: (e, el) => {

      console.log("onclick", e, el)
      var activeElement = el[0];

      if(!activeElement) return;
      var data = activeElement._chart.data;
      console.log("data", data)

      var barIndex = activeElement._index;
      var datasetIndex = activeElement._datasetIndex;

      var datasetLabel = data.datasets[datasetIndex].label;
      var xLabel = data.labels[barIndex];
      var yLabel = data.datasets[datasetIndex].data[barIndex];

      console.log(datasetLabel, xLabel, yLabel);
      //   const canvasPosition = Chart.helpers.getRelativePosition(e, this.chart);

      // // Substitute the appropriate scale IDs
      //  const dataX = this.chart.scales.x.getValueForPixel(canvasPosition.x);

      //console.log("datax",dataX)
      // const dataY = chart.scales.y.getValueForPixel(canvasPosition.y);
    }
    ,
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          return (
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
              .name +
            "\n " +
            ""
          );
        },



      },
    },

    chartArea: {
      backgroundColor: "lightgrey",
    },

    elements: {
      point: {
        borderWidth: 5,
        hoverBorderWidth: 10,
        radius: 20,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            min: 1,
           // max: 25,
            max: 5,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            min: 1,
            max: 5,
            //max: 25,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
          anchor: function (context) {
              var value = context.dataset.data[context.dataIndex];
              return value.x < 1000 ? 'end' : 'center';
          },
          align: function (context) {
              var value = context.dataset.data[context.dataIndex];
              return value.x < 1000 ? 'end' : 'center';
          },
          color: function (context) {
              var value = context.dataset.data[context.dataIndex];
              return value.x < 1000 ? context.dataset.backgroundColor : 'white';
          },
          font: {
              weight: 'bold'
          },
          formatter: function (value, context) {
              return 'test'//context.dataset.label;
          },
          offset: 2,
          padding: 0
      }
  },
  };

  // data = {
  //   labels: ["a", "b", "c", "d"],
  //   rows: [[1, 2, 3, 50], [4, 5, 6, 64], [7, 8, 9, 72]]
  // };

  constructor(private dummy: DummyService) {
    //   //console.log("c-DATA", this.data);
  }

  ngOnInit() {
    //  //console.log("o-DATA", this.data);
  }

  @ViewChild("graphCanvas") graphCanvas: ElementRef;
  ngAfterViewInit() {
    //https://blog.angular-university.io/angular-debugging/
    setTimeout(() => {
      var g;

      ////////////////
      Chart.pluginService.register({
        beforeDraw: function (chart, easing) {
          if (
            chart.config.options.chartArea &&
            chart.config.options.chartArea.backgroundColor
          ) {
            var helpers = Chart.helpers;
            var ctx = chart.chart.ctx;
            var chartArea = chart.chartArea;

            //  g= ctx.createLinearGradient(0,0,ctx.canvas.width,0);

            //     g.addColorStop(0, 'white');
            //     g.addColorStop(1, 'blue');

            ctx.save();
            ctx.fillStyle = chart.config.options.chartArea.backgroundColor;
            ctx.fillRect(
              chartArea.left,
              chartArea.top,
              chartArea.right - chartArea.left,
              chartArea.bottom - chartArea.top
            );

            ctx.restore();
          }
        },
      });

      //data wa shere
      let data = this.getData();
      this.chart = new Chart(this.graphCanvas.nativeElement, {
       // plugins:[ChartDataLabels],
        type: "bubble",
        data: {
          labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
          datasets: [
            {
              backgroundColor: "white",
              //  backgroundColor:'linear-gradient(orange, yellow)',
              label: this.graphtitle,
              data: data
            },
          ],
        },
        options: this.options,
      });



      var gradient = this.graphCanvas.nativeElement
        .getContext("2d")
        .createLinearGradient(
          0,
          this.chart.canvas.height,
          this.chart.canvas.width,
          0
        );

      gradient.addColorStop(1, "green");
      gradient.addColorStop(0.5, "orange");
      gradient.addColorStop(0, "red");

      this.chart.options.chartArea.backgroundColor = gradient;
      this.chart.update();

      ///////////////////
    }, 1);
  }
  getData() {

    let data = [];

    if (!this.solutions || this.solutions.length == 0) return data;
    if (this.graphtype == "Desirability/Feasability") {
      console.log("init Desirability/Feasability");
      this.solutions.forEach((s) => {
        data.push(
          this.calcData(
            s.parameters.effects,
            s.parameters.feasibilities,
            s.text
          )
        );
      });
    } else if (this.graphtype == "Desirability/Risk") {
      console.log("init Desirability/Risk");
      this.solutions.forEach((s) => {
        data.push(
          this.calcData(s.parameters.effects, s.parameters.risks, s.text)
        );
      });
    } else if  (this.graphtype == "Feasability/Risk"){
      console.log("init Feasability/Risk");
      this.solutions.forEach((s) => {
        data.push(
          this.calcData(
            s.parameters.feasibilities,
            s.parameters.risks,
            s.text
          )
        );
      });

      //   console.log('data', data)
    }
else{
  console.log("Fel typ",this.graphtype)
}

    return data;
  }


  updatechart2() {


    let data = this.getData();


    if (!this.chart) {
      // console.log("chart not init return");
      return;
    }
    // let data2 = {
    //   id: '666',
    //   name: 'satan',
    //   x: 6,
    //   y: 6


    // }
    // console.log('data', data)

    this.chart.data.datasets.forEach((dataset) => {
      dataset.data.pop();
    });

    this.chart.data.datasets.forEach((dataset) => {

      dataset.data = [];
      dataset.data.forEach(element => {
        // console.log("old data", element)
      });
    });
    this.chart.data.datasets.forEach((dataset) => {
      //   console.log('ds', dataset)

      data.forEach(element => {
        dataset.data.push(element);
      });

      //dataset.data.push(data2);
    });

    this.chart.update();
  }

  calcData(p1: any, p2: any, text: any): any {
    let s: any = {};

    s.name = text;
    s.id = "id_123";

    let avgX =
      p1.reduce((acc, curr) => {
        return acc + curr.value ;
      //  return acc + curr.value * curr.weight;
      }, 0) / p1.length;

    let avgY =
      p2.reduce((acc, curr) => {
        return acc + curr.value ;
        //return acc + curr.value * curr.weight;
      }, 0) / p2.length;

    s.x = avgX;
    s.y = avgY;

    //console.log("ide ",s);
    return s;
  }
  calcAxis() {
    this.solutions.forEach((el) => {
      let s: any = {};

      s.name = el.text;
      s.id = "id_123";

      let avgX =
        el.parameters.feasibilities.reduce((acc, curr) => {
          return acc + curr.value * curr.weight;
        }, 0) / el.parameters.feasibilities.length;

      let avgY =
        el.parameters.effects.reduce((acc, curr) => {
          return acc + curr.value * curr.weight;
        }, 0) / el.parameters.feasibilities.length;

      s.x = avgX;
      s.y = avgY;
      //console.log("avgX " + avgX);
      //console.log("avgY " + avgY);
      el.parameters.feasibilities.forEach((p) => {
        //console.log(p);
      });
      //console.log(s)
    });
  }

  createTable(): any {
    //console.log("setting show table", this.showTable);
    this.showTable = true;
    //console.log("setted show table", this.showTable);
  }
  createLineChart(): any {
    this.chart = new Chart(this.graphCanvas.nativeElement, {
      responsive: true,
      maintainAspectRatio: false,
      type: "line",
      data: {
        labels: this.data.labels,
        datasets: [
          {
            data: this.data.values,
            borderColor: "#3cba9f",
            fill: false,
            yAxisID: "A",
            backgroundColor: [
              "#FF6384",
              "#63FF84",
              "#84FF63",
              "#8463FF",
              "#6384FF",
            ],
          },
          {
            data: [],
            borderColor: "#4286f4",
            fill: false,
            yAxisID: "B",

            hidden: true,
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              display: true,
              //  type: "time",
              time: {
                displayFormats: {
                  minute: "HH:mm",
                  hour: "HH:mm",
                },
              },
            },
          ],
          yAxes: [
            {
              id: "A",
              type: "linear",
              position: "left",
              ticks: {
                callback: function (value, index, values) {
                  return value; // + "°C";
                },
              },
            },
          ],
        },
      },
    });
  }
  updateChart(): any {
    this.chart.data.labels = [];
    this.chart.data.datasets[0].data = [];
    this.chart.data.datasets[1].data = [];

    let values = this.dummy.getArray(10, 1, 10);

    let count = 1;
    if (values) {
      values.forEach((temp) => {
        let value = temp;

        this.chart.data.labels.push(count++);
        this.chart.data.datasets[0].data.push(value);
      });
    }

    this.chart.update();
  }

  createPie() {
    this.chart = new Chart(this.graphCanvas.nativeElement, {
      //   responsive:true,
      // maintainAspectRatio: false,
      type: "pie",
      data: {
        labels: this.data.labels,
        datasets: [
          {
            data: this.data.values,
            backgroundColor: [
              "#FF6384",
              "#63FF84",
              "#84FF63",
              "#8463FF",
              "#6384FF",
            ],
            // borderColor: "#3cba9f",
            // fill: false,
            //   yAxisID: "A"
          },
        ],
      },
      options: {
        legend: {
          display: true,
          position: "right",
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              //get the concerned dataset
              var dataset = data.datasets[tooltipItem.datasetIndex];
              //calculate the total of this data set
              var total = dataset.data.reduce(function (
                previousValue,
                currentValue,
                currentIndex,
                array
              ) {
                return previousValue + currentValue;
              });
              //get the current items value
              var currentValue = dataset.data[tooltipItem.index];
              //calculate the precentage based on the total and current item, also this does a rough rounding to give a whole number
              var percentage = Math.floor((currentValue / total) * 100 + 0.5);

              return currentValue + " (" + percentage + "%)";
            },
          },
        },
      },
    });
    //   this.chart.data.labels = [];
    //   this.chart.data.datasets[0].data = [];
    //   this.chart.data.datasets[1].data = [];

    //   let values=this.dummy.getArray(5,1,10);

    //   let labels=this.dummy.getWordArray(5);
    //   let count = 1;

    // for (let i = 0; i < values.length; i++) {
    //   const  v  = values[i];
    //   this.chart.data.labels.push(labels[i]);
    //   this.chart.data.datasets[0].data.push(v);

    // }

    // values.forEach(temp => {
    //   let value = temp;

    //   this.chart.data.labels.push(count++);
    //   this.chart.data.datasets[0].data.push(value);

    // });

    this.chart.update();
  }
  createBarChart() {
    this.chart = new Chart(this.graphCanvas.nativeElement, {
      responsive: true,
      maintainAspectRatio: false,
      type: "bar",
      data: {
        labels: this.data.labels,
        datasets: [
          {
            data: this.data.values,
            borderColor: "#3cba9f",
            fill: false,
            yAxisID: "A",
            backgroundColor: [
              "#FF6384",
              "#63FF84",
              "#84FF63",
              "#8463FF",
              "#6384FF",
            ],
          },
          {
            data: [],
            borderColor: "#4286f4",
            fill: false,
            yAxisID: "B",

            hidden: true,
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              display: true,
              //  type: "time",
              // time: {
              //   displayFormats: {
              //     minute: "HH:mm",
              //     hour: "HH:mm"
              //   }
              // }
            },
          ],
          yAxes: [
            {
              id: "A",
              // type: "linear",
              // position: "left",
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });

    // this.updateChart();
  }
}
