import { Provider } from "@angular/core";

export interface Round {
  dataset: string;
  desc: string;
  selectedService: any;
  serviceName: string;
  created: any;
  lastUpdate: any;
  description: string;

  actors: any[];
  machines: any[];

  process;
  processes: any[]; //todo process evlas
  name: string;
  // processAss: ProcessEval[];
  processAss2: ProcessEval2[];
  id: string;


  objective?:string;
  outcome?:string;
  docs?;
}

export interface ProcessEval2 {
  statementAss2: StatementAssement2[];
  processId: string;
  processName: string;
}

export interface StatementAssement2 {
  expanded: boolean;

  statement: any;
  rating: number;
  comment: string;
  humanAss: HumanAss[];
  machineAss: BaseMachineData[];

  ideas: Idea[];
}

export class ProcessEval1 {
  constructor(process: Process) {
    this.process = process;
    process.statements.forEach(statement => {
      let actors;
      this.statementAsses.push(new StatementAssement(statement));
    });
  }

  addActor(actor: Actor) {
    this.statementAsses.forEach(ass => {
      let humanAss = new HumanAss(actor);
      humanAss.rating = -1;

      ass.humanAss.push(humanAss);
    });
  }
  process: Process;

  statementAsses: StatementAssement[] = [];
  created: Date;
}

// expanded: false,
// Text:
//   "The service provider and the customer have jointly agreed upon a documented strategy for how to manage events.",
// Human: 4,
// Machine: 1,
// Mutual: 2.5,
// Comment: this.dummy.comment(5),
// // IdeasSummary: "idea 1, idea 2...",
// machineAss: [],

// humanAss: [
//   {
//     name: "Actor 1",
//     rating: Math.round(Math.random() * 5),
//     comment: this.dummy.comment(5)
//   },

//   {

export class StatementAssement {
  expanded: boolean = true;

  statement: Statement;
  rating: number = -1;
  changedrating: number = -1;
  comment: string;
  humanAss: HumanAss[] = [];
  machineAss: MachineAss[] = [];

  ideas: Idea[] = [];

  constructor(statement: Statement) {
    this.statement = statement;
  }
}
export interface Process {
  name: string;
  description: string;
  statements: Statement[];
  created: Date;
  id: any;
}

export class Statement {
  [x: string]: any;
  text: string;
  description: string;

  baseMachineData: BaseMachineData[];
}

export class BaseMachineData {
  values: any[];
  labels: string[];

  name: string;
  description: string;
  viewType: string;
  rating: number;
}

export class MachineData {
  name: string;
  description: string;
  rating: number;
}

export class HumanAss {
  constructor(actor: Actor) {
 // constructor() {
    this.actor = actor;
    this.rating = -1;
    this.comment = "";
  }

actor: Actor;
  statementId;
  statementName:string;
  statementDesc:string;
  rating: number;
  comment: string;



  ideas: Idea[];
  pairs:any[];
}

export class MachineAss {
  constructor(base: BaseMachineData) {
    this.base = base;
    this.rating = base.rating;
    this.comment = "";
  }

  base: BaseMachineData;
  rating: number;
  comment: string;
}

export interface Idea {
  actorId: any;
  solution: any;
  problem: any;
  text: string;
  id: string;
  statementId:string;
  roundId:string;
  source: string;
  value: number;
  comment: string;
  prio: boolean;
  created: any;
}

export interface Actor {
  finished: boolean;
  id: string;
  name: string;
  email: string;
  comment: string;
  link: string;

  roundId:string;
  roundName:string;
  processAssessments: ActorProcessAssessment[];

  role?:string;
}

export interface ActorProcessAssessment {
  processName: string;
  processDesc: string;
  processId: string;

  statementAssessments: HumanAss[];
}
