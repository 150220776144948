import { Component, OnInit } from '@angular/core';
import { ThrowStmt } from '@angular/compiler';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-interdependency',
  templateUrl: './interdependency.component.html',
  styleUrls: ['./interdependency.component.css']
})
export class InterdependencyComponent implements OnInit {

  test = 2;
  // interdeps: any = {

  //   "tasks": [
  //     {
  //       "no":1,
  //       "name": "Identify problem/opportunity",
  //       "subtasks": [

  //         {
  //           subtask: "apa",
  //           caps: [
  //             { text: "banan" }
  //           ]
  //         },
  //         {
  //           subtask: "cirkus",
  //           caps: [
  //             { text: "dalsland" },
  //             { text: "eskimå" },
  //           ]
  //         },


  //       ]
  //     },
  //     {
  //       "no":2,
  //       "name": "Frame the choice",
  //       "subtasks": [{
  //         subtask: "gurka",
  //         caps: [
  //           { text: "melon" }
  //         ]
  //       },
  //       ]
  //     },
  //     {
  //       "no":3,
  //       "name": "Select",
  //       "subtasks": [
  //         {
  //         subtask: "tomat",
  //         caps: [

  //         ]
  //       }

  //     ,

  //         {
  //         subtask: "chips",
  //         caps: [

  //         ]
  //       }

  //     ,




  //     ]
  //     },



  //     {
  //       "no":4,
  //       "name": "Review",
  //       "subtasks": []
  //     },


  //   ]
  // }

  interdeps: any;
  step = 1;

  constructor(private afs: AngularFirestore) {


  }

  save() {
    //console.log("saving");
    this.afs.collection("interdeps").doc("prototype").set(
      this.interdeps
    )
  }

  ngOnInit() {

    this.afs.collection("interdeps").doc("prototype").get().subscribe(data => {
      //console.log("got", data.data());
      this.interdeps = data.data();
    });
  }

  stepInc(n) {
    this.step += n;


    if (n > 0) this.save();
  }

  getRows() {






    this.interdeps.tasks.forEach(task => {

      let taskrowspan = 0;
      task.subtasks.forEach(subtask => {
        let subtaskrowspan = 0;
        subtask.caps.forEach(cap => {
          //  //console.log("Cap: "+cap.text);
          taskrowspan++
          subtaskrowspan++;
        });

        subtask.rowspan = subtaskrowspan;
      });
      task.rowspan = taskrowspan;

    });


    return this.interdeps.tasks;
  }

  addSubTask(item) {

    item.subtasks.push(
      {
        subtask: "",
        caps: [

        ]
      }
    )
  }


  removeCab(sub, i) {

    sub.caps.splice(i, 1);
  }

  addCab(item) {
    //console.log(item);
    item.caps.push(
      { text: "" }
    )
  }

  removeSubTask(item, index) {

    item.subtasks.splice(index, 1);

  }

  getColor(val) {


    switch (val) {
      case "1":
        return "#c8e4a9"
      case "2":
        return "#f8f68c"
      case "3":
        return "#fcde88"
      case "4":
        return "#f68f92"


    }

    return "";
  }

}
