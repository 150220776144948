// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyB7JN2W7APe9co_DJvZooFQr4UZPaO39Eo",
    authDomain: "hydemasy2.firebaseapp.com",
    databaseURL: "https://hydemasy2.firebaseio.com",
    projectId: "hydemasy2",
    storageBucket: "hydemasy2.appspot.com",
    messagingSenderId: "429561295255",
    appId: "1:429561295255:web:281c1ec6d5759770a914d2"



    // apiKey: "AIzaSyAV337rotthC4rRFxwMTLD4VS31aS46zhA",
    // authDomain: "hydemasy2021.firebaseapp.com",
    // projectId: "hydemasy2021",
    // storageBucket: "hydemasy2021.appspot.com",
    // messagingSenderId: "919427736301",
    // appId: "1:919427736301:web:978585b9bfb35bbe42e2e4"

  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
