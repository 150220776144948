import { Component, OnInit } from "@angular/core";
import { Round, ProcessEval2, Process } from "../../round";
import { DataService } from "../../data.service";
import { DummyService } from "../../dummy.service";
import { ActivatedRoute } from "@angular/router";
import {
  AngularFirestore,
  AngularFirestoreCollection
} from "@angular/fire/firestore";
import { map } from "rxjs/operators";

@Component({
  selector: "app-ideas",
  templateUrl: "./ideas.component.html",
  styleUrls: ["./ideas.component.css"]
})
export class IdeasComponent implements OnInit {
  viewData: any;

  round: Round;

  processes: Process[];
  id: string;
  processesCollection: AngularFirestoreCollection<{}>;

  constructor(
    private data: DataService,
    private dummy: DummyService,
    private route: ActivatedRoute,
    private afs: AngularFirestore
  ) {
    // if (data.currentRound === undefined) data.currentRound = data.rounds[0]; //todo navigera
    // this.round = data.currentRound;
    // let currentProcess = data.currentRound.processes[0]; //todo
    // this.processes = data.currentRound.processAss;
    // //console.log("ideas ", this.processes);
    // this.processes.forEach(procAss => {
    //   procAss.statementAsses = procAss.statementAsses.filter(
    //     sAss => sAss.ideas.length > 0
    //   );
    // });
    // //console.log("ideas ", this.processes);
  }

  ngOnInit() {
    this.processesCollection = this.afs.collection<Process>("processes");

    this.route.paramMap.subscribe(params => {
      if (params.has("id")) {
        this.id = params.get("id");
        this.afs
          .collection<Round>("rounds")
          .doc(this.id)
          .valueChanges()
          .subscribe(r => {
            this.round = r as Round;
            //console.log(r);

            this.viewData = {};

            let vd = {
              name: "RoundName",
              created: "CreationDate",

              processes: []
            };

            if (!this.round.processes) this.round.processes = [];

            this.round.processAss2.forEach(pAss => {
              let process = {
                name: pAss.processName,
                desc: "desc",
                statements: []
              };

              pAss.statementAss2.forEach(sAss => {
                let statement = {
                  name: sAss.statement.text,
                  desc: sAss.statement.description,
                  human: sAss.rating,
                  ideas: []
                };

                sAss.ideas.forEach(i => {
                  let idea = {
                    name: i.text,
                    comment: i.comment
                  };

                  statement.ideas.push(idea);
                });

                sAss.humanAss.forEach(hAss => {
                  hAss.ideas.forEach(i => {
                    let idea = {
                      name: i.text,
                      comment: i.comment
                    };

                    statement.ideas.push(idea);
                  });
                });

                process.statements.push(statement);
              });

              vd.processes.push(process);
            });

            this.viewData = vd;

            //   this.processesCollection
            //     .snapshotChanges()
            //     .pipe(
            //       map(action =>
            //         action.map(a => {
            //           const data = a.payload.doc.data() as Process;
            //           const id = a.payload.doc.id;
            //           return { id, ...data };
            //         })
            //       )
            //     )
            //     .subscribe(a => {
            //  //console.log("got p ", a);

            //       this.processes = a;

            //

            //       this.viewData.tjo = "klar";

            //     });
          });
      }
    });
  }
}
