import { Component, OnInit } from "@angular/core";

import { Router, ActivatedRoute } from "@angular/router";

import {
  AngularFirestore,
  AngularFirestoreCollection
} from "@angular/fire/firestore";
import { map } from "rxjs/operators";
import {
  Process,
  Round,
  Actor,
  StatementAssement2,
  ProcessEval2,
  ActorProcessAssessment,
  HumanAss
} from "../../round";
import { DummyService } from "../../dummy.service";
import { DataService } from "../../data.service";
import { PlatformLocation } from "@angular/common";
import { AuthService } from "src/app/auth.service";
import { a } from "@angular/core/src/render3";
import { ScoreService } from "src/app/score.service";
import { registerContentQuery } from "@angular/core/src/render3/instructions";
import * as firebase from "firebase";
import { ActorAssComponent } from "../actor-ass/actor-ass.component";

@Component({
  selector: "app-round",
  templateUrl: "./round.component.html",
  styleUrls: ["./round.component.css"]
})
export class RoundComponent implements OnInit {
  round: Round;

  savedMsg: string = "";

  selectedProcesses: Process[] = [];
  availableProcesses: Process[] = [];

  services: any;
  s2: any[] = [];

  processes: Process[];
  id: string;
  processesCollection: AngularFirestoreCollection<{}>;
  domainUrl: string;
  edit: boolean;
  fakedate: any;
  constructor(
    private dummy: DummyService,
    public dat2a: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private afs: AngularFirestore,
    private loc: PlatformLocation,
    private auth: AuthService,
    public score: ScoreService
  ) {
    //console.log("constr.");

    const url = window.location.href;

    this.domainUrl = url.replace(loc.pathname, "");

    //console.log("apa " + this.domainUrl);
    //console.log("urk " + url);

    this.services = this.afs
      .collection<any>(
        "services",
        ref => ref.where("userid", "==", "y2wSMtxxOfburOuwYC7hCntVIFt1") //todo ta från auth
      )
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(a => {
            //     //console.log("am i running=")
            const data = a.payload.doc.data() as any;

            data.id = a.payload.doc.id;
            this.s2.push(data);
            return data;
          });
        })
      );
  }

  addActor() {
    if (!this.round.actors) this.round.actors = [];

    let a = <Actor>{};
    a.name = ""; // this.dummy.firstName();
    a.link = this.dummy.uid(4);
    a.id = this.afs.createId();
    this.round.actors.push(a);
  }

  removeActor(i) {
    this.round.actors.splice(i, 1);
  }

  cancel() {
    //  this.data.editRound(null);
    this.router.navigateByUrl("/projects");
  }

  save2(leave: boolean) {


    // this.round.processes = this.selectedProcesses.map(p => {
    //   return p;

    // });


    //console.log("New save!", this.round);
    this.round.serviceName = this.getServiceName(this.round.selectedService);

    let rounddata = { name: this.round.name };
    // let processes = [
    //   {
    //     processId: "abc",
    //     processName: "process namn",

    //     statements: [
    //       {
    //         statementId: "cde",
    //         text: "påstående"
    //       }
    //     ]
    //   }
    // ];


    //save actors
    //console.log("actors ", this.round.actors);
    this.round.actors.forEach(a => {
      a.roundName = this.round.name;
      a.roundId = this.round.id;
      this.afs.collection("actors").doc(a.id).set(a);
    })


    this.round.process = this.selectedProcesses[0];
    delete this.round.processes;//todo why?
    //console.log("round!", this.round);


    this.round.dataset = this.score.filename;


    //this.round.actors
    if (this.edit) {
      //console.log("saving edit", this.round)
      let r: any = {
        name: this.round.name,
        desc: this.round.desc,
        objective: this.round.objective,
        outcome: this.round.outcome,

        actors: this.round.actors,
        //  dataset: this.round.dataset  //todo updatera dataset on edit
      }


      if (this.round.docs) r.docs = this.round.docs;


      console.log("trying to save proj", r)

      this.afs
        .collection("nrounds")
        .doc(this.round.id)
        .update(r).then(res=>{
          console.log("saved edit!",res)
          this.leave(leave);
        })


    } else {
//nytt projekt
      if (!this.round.name) this.round.name = "";

      this.afs
        .collection("nrounds")
        .doc(this.round.id)
        .set(this.round).then(res=>{
          console.log("saved new!",res)
          this.leave(leave);
        });
      this.afs.collection('users').doc(this.auth.userid).update({ currentRound: this.round.id })

    }



 


    let process = this.processes

    this.round;

    //save round
    // this.afs
    //   .collection("nrounds")
    //   .doc(this.round.id)
    //   .set(this.round)
    //   .then(() => {
    //     processes.forEach(p => {
    //       this.afs
    //         .collection("nrounds")
    //         .doc(this.round.id)
    //         .collection("processes")
    //         .doc(p.processId)
    //         .set({ name: p.processName })
    //         .then(() => {
    //           p.statements.forEach(s => {
    //             this.afs
    //               .collection("nrounds")
    //               .doc(this.round.id)
    //               .collection("processes")
    //               .doc(p.processId)
    //               .collection("statements")
    //               .doc(s.statementId)
    //               .set({
    //                 text: s.text
    //               });
    //           });
    //         });
    //     });
    //   });

    //this.router.navigateByUrl("/");
  }
  leave(leave: boolean) {
    console.log("leaving",leave)
    if (leave) this.router.navigateByUrl("/projects");
    else {
      this.savedMsg = "Saved!";

      setTimeout(() => {
        this.savedMsg = "";
      }, 2000)
    }
    return;
  }


  finish() {
    this.save2(true);
  }
  //todo refaktor
  // save() {
  //   this.round.serviceName = this.getServiceName(this.round.selectedService);

  //   // //console.log(this.selectedProcesses);
  //   // return;
  //   if (!this.round.actors) this.round.actors = [];
  //   this.round.processes = this.selectedProcesses.map(p => {
  //     return p;
  //     // return {
  //     //   id: p.id,
  //     //   name: p.name
  //     // };
  //   });

  //   //process ass begin
  //   // if (!this.round.processAss2) this.round.processAss2 = [];
  //   // this.selectedProcesses.forEach(process => {
  //   //   if (
  //   //     this.round.processAss2
  //   //       .map(p => {
  //   //         return p.processId;
  //   //       })
  //   //       .includes(process.id)
  //   //   )
  //   //     var a1 = {};
  //   //   var statementAssesa = [];
  //   //   let a = [];
  //   //   process.statements.forEach(statement => {
  //   //     let a = <StatementAssement2>{
  //   //       statement: statement,
  //   //       expanded: false,
  //   //       comment: "",
  //   //      humanAss:[],

  //   //       ideas: [],
  //   //       machineAss: statement.baseMachineData.map(b => {
  //   //         return b;
  //   //       }),
  //   //       rating: -1
  //   //     };
  //   //     statementAssesa.push(a);
  //   //   });

  //   //   this.round.processAss2.push(<ProcessEval2>{
  //   //     processId: process.id,
  //   //     processName: process.name,
  //   //     statementAss2: statementAssesa
  //   //   });
  //   // });
  //   //process ass end

  //   let actors = [];

  //   actors = this.round.actors.map(a => {
  //     return <Actor>{
  //       name: a.name,
  //       id: a.id,
  //       link: a.link,
  //       email: "",
  //       roundId: this.round.id,
  //       roundName: this.round.name,

  //       processAssessments: this.selectedProcesses.map(p => {
  //         return <ActorProcessAssessment>{
  //           processName: p.name,
  //           processDesc: p.description,
  //           processId: p.id,
  //           statementAssessments: p.statements.map(s => {
  //             return <HumanAss>{
  //               actor: a,
  //               statementId: s.id,
  //               statementName: s.text,
  //               statementDesc: s.description,
  //               comment: "",
  //               ideas: [],
  //               pairs: [],
  //               rating: -1
  //             };
  //           })
  //         };
  //       })
  //     };
  //   });

  //   this.round.actors = this.round.actors.map(a => {
  //     return <Actor>{
  //       name: a.name,
  //       id: a.id,
  //       link: a.link,
  //       email: ""
  //       // roundId:this.round.id,
  //       // roundName:this.round.name,

  //       // processAssessments:this.selectedProcesses.map(p=>{
  //       //   return <ActorProcessAssessment>{
  //       //     processName:p.name,
  //       //     processDesc:p.description,
  //       //     processId:p.id,
  //       //     statementAssessments: p.statements.map(s=>{
  //       //       return <HumanAss>{
  //       //        actor:a,
  //       //         statementId:s.id,
  //       //         statementName:s.text,
  //       //         statementDesc:s.description,
  //       //         comment:"",
  //       //         ideas:[],
  //       //         pairs:[],
  //       //         rating:-1
  //       //       }
  //       //     })
  //       //   }
  //       // })
  //     };
  //   });

  //   //console.log("saving round.... ", this.round);

  //   actors.forEach(actor => {
  //     //this.round.actors.forEach(actor => {
  //     this.afs
  //       .collection("actors")
  //       .doc(actor.id)
  //       .get()
  //       .toPromise()
  //       .then(snap => {
  //         if (snap.exists) {
  //           //console.log("actor exist");
  //           this.afs
  //             .collection("actors")
  //             .doc(actor.id)
  //             .update(actor);
  //         } else {
  //           //console.log("actor not exist");
  //           this.afs
  //             .collection("actors")
  //             .doc(actor.id)
  //             .set(actor);
  //         }
  //       });
  //   });

  //   this.afs
  //     .collection("rounds")
  //     .doc(this.round.id)
  //     .get()
  //     .toPromise()
  //     .then(snap => {
  //       if (snap.exists) {
  //         //console.log("round exist");
  //         this.afs
  //           .collection("rounds")
  //           .doc(this.round.id)
  //           .update(this.round);
  //       } else {
  //         //console.log("round not exist");
  //         this.afs
  //           .collection("rounds")
  //           .doc(this.round.id)
  //           .set(this.round);
  //       }
  //     });

  //   //  if (!this.data.eRound) this.data.rounds.push(this.round);
  //   this.router.navigateByUrl("");
  // }
  getServiceName(id: any): string {
    let name = "-";

    this.s2.forEach(s => {
      //console.log(s);
      if (s.id == id) {
        name = s.name;
      }
    });
    return name;
  }

  ngOnInit() {

    this.newInit();
    return;
    this.processesCollection = this.afs.collection<Process>("processes");

    // this.processesCollection.valueChanges().subscribe(p=>{
    //   this.processes=p as Process[];
    // });

    // this.data.processes.subscribe(shite => {
    //   this.processes = shite;
    // });
    // if (this.data.eRound) this.round = this.data.eRound;
    // else {
    // }
    // this.selectedProcesses = [];

    // this.round.processes.forEach(selP => {
    //   this.selectedProcesses.push(selP);
    // });

    // this.processes = [];

    // data.processes.forEach(process => {
    //   if (!this.selectedProcesses.includes(process))
    //     this.processes.push(process);
    // });
    //  this.processes = [];

    this.route.url.subscribe(url => {
      if (url[1].path.toLowerCase() === "new") {
        //console.log("newmode!");
        this.round = <Round>{}; //data.createDummyRound();
        this.round.id = this.afs.createId();
        this.round.processes = [];
        this.selectedProcesses = [];

        this.processesCollection
          .snapshotChanges()
          .pipe(
            map(action =>
              action.map(a => {
                const data = a.payload.doc.data() as Process;
                const id = a.payload.doc.id;
                return { id, ...data };
              })
            )
          )
          .subscribe(a => {
            //console.log("got ", a);
            this.processes = a;
            this.availableProcesses = a;
          });

        //console.log("processes", this.processes);
        // this.processes.forEach(process => {
        //   if (!this.selectedProcesses.includes(process))
        //     this.availableProcesses.push(process);
        // });
      } else {
        this.route.paramMap.subscribe(params => {
          //let item=new Item();
          //console.log("edit mode!");
          //console.log("param keys " + params.keys);
          if (params.has("id")) {
            this.id = params.get("id");
            this.afs
              .collection<Round>("rounds")
              .doc(this.id)
              .valueChanges()
              .subscribe(r => {
                this.round = r as Round;
                //console.log(r);
                if (!this.round.processes) this.round.processes = [];
                this.selectedProcesses = [];

                this.processesCollection
                  .snapshotChanges()
                  .pipe(
                    map(action =>
                      action.map(a => {
                        const data = a.payload.doc.data() as Process;
                        const id = a.payload.doc.id;
                        return { id, ...data };
                      })
                    )
                  )
                  .subscribe(a => {
                    //console.log("got p ", a);
                    //console.log("have p ", this.round.processes);

                    this.processes = a;

                    this.processes.forEach(process => {
                      if (
                        !this.round.processes
                          .map(p => {
                            return p.id;
                          })
                          .includes(process.id)
                      )
                        this.availableProcesses.push(process);
                      else this.selectedProcesses.push(process);
                    });
                  });
              });

            // this.afs.collection('processes').doc( this.id).get().subscribe(o=>{
            //  this.process=o.data() as Process;
            // });
          }
        });
      }
    });
  }




  newInit(): any {


    this.processesCollection = this.afs.collection<Process>("processes", ref => ref.orderBy('order'));



    this.route.url.subscribe(url => {
      if (url[1].path.toLowerCase() === "new") {
        //console.log("newmode!");
        this.round = <Round>{};
        this.round.id = this.afs.createId();
        this.round.processes = [];
        this.round.name = "";
        this.fakedate = new Date();

        this.round.created = firebase.firestore.FieldValue.serverTimestamp();
        //console.log(this.round.created);
        this.selectedProcesses = [];


        this.round.objective = "To improve our business by identifying problems and possible solutions related to the selected ITIL process.";
        this.round.outcome = "One or several problem and solution pair(s) for the selected process.";
        this.round.desc = "In this specific project we (human users and digital technology) identify possible problems and solutions in the selected ITIL process. Firstly, based on the scores from human assessment and information given by machine, human users can identify and prioritize problem(s); Second, can identify solutions to the problem.  Finally, a solution could be selected (decided) by evaluating the different solutions)";



        this.processesCollection
          .snapshotChanges()
          .pipe(
            map(action =>
              action.map(a => {
                const data = a.payload.doc.data() as Process;
                const id = a.payload.doc.id;
                return { id, ...data };
              })
            )
          )
          .subscribe(a => {
            //console.log("got ", a);
            this.processes = a;
            this.availableProcesses = a;
          });

        //console.log("processes", this.processes);





      }

      else {

        //console.log("old mode")

        this.edit = true;
        this.route.paramMap.subscribe(params => {


          if (params.has("id")) {
            this.id = params.get("id");
            this.afs
              .collection<Round>("nrounds")
              .doc(this.id)
              .valueChanges()
              .subscribe(r => {

                r = r as any;

                this.round = r as Round;
                //console.log("Round:", r);
                this.fakedate = this.round.created.toDate();

                this.selectedProcesses[0] = this.round.process;
                //this.fakedate=firebase.firestore.Timestamp.fromDate(this.round.created);
                //r.created;

                this.round.id = this.id;


                return;
                if (!this.round.processes) this.round.processes = [];
                this.selectedProcesses = [];

                this.processesCollection
                  .snapshotChanges()
                  .pipe(
                    map(action =>
                      action.map(a => {
                        const data = a.payload.doc.data() as Process;
                        const id = a.payload.doc.id;
                        return { id, ...data };
                      })
                    )
                  )
                  .subscribe(a => {
                    //console.log("got p ", a);
                    //console.log("have p ", this.round.processes);

                    this.processes = a;

                    this.processes.forEach(process => {
                      if (
                        !this.round.processes
                          .map(p => {
                            return p.id;
                          })
                          .includes(process.id)
                      )
                        this.availableProcesses.push(process);
                      else this.selectedProcesses.push(process);
                    });
                  });
              });


          }
        });
      }
    });
  }

  selectProcess() {

    //console.log("select!", this.selectedUnselectedProcess);
    if (
      !this.selectedUnselectedProcess ||
      this.selectedUnselectedProcess === -1
    )
      return;

    let p = this.availableProcesses[this.selectedUnselectedProcess];
    this.selectedProcesses = [];
    this.selectedProcesses.push(p);

    // this.availableProcesses.splice(this.selectedUnselectedProcess, 1);

    //   this.selectedUnselectedProcess = -1;
  }

  unselectProcess() {
    if (!this.selectedSelectedProcess || this.selectedSelectedProcess === -1)
      return;

    //console.log(this.selectedProcesses);

    let p = this.selectedProcesses[this.selectedSelectedProcess];

    //console.log("unselecting", p);

    this.availableProcesses.push(p);

    this.selectedProcesses.splice(this.selectedSelectedProcess, 1);
    this.selectedSelectedProcess = -1;
  }

  selectedUnselectedProcess; //best names ever
  selectedSelectedProcess;

  selectChangeHandler($event) {
    this.selectedUnselectedProcess = $event.target.value;
  }
  select2ChangeHandler($event) {
    this.selectedSelectedProcess = $event.target.value;
  }

  get selectable(): boolean {
    return false;
  }

  selectAllProcess() {
    if (this.availableProcesses.length === 0) return;

    for (let index = this.availableProcesses.length - 1; index > -1; index--) {
      this.selectedProcesses.push(this.availableProcesses.splice(index, 1)[0]);
    }
  }
  unselectAllProcess() {
    if (this.selectedProcesses.length === 0) return;
    for (let index = this.selectedProcesses.length - 1; index > -1; index--) {
      this.availableProcesses.push(this.selectedProcesses.splice(index, 1)[0]);
    }
  }

  refreshLink(i) {
    this.round.actors[i].link = this.dummy.uid(4);
  }



  //upload machine data
  onFileChange(evt: any) {

    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      //todo lägg i score servive
      try {
        this.score.handleFile(e, this.selectedProcesses[0], target.files[0]);
      } catch (err) {
        this.score.scoringResultInfo = "Analysing failed. Please ensure that selected document contains correct data."
        this.score.scoringResult = -1;
      }

    };

    reader.readAsBinaryString(target.files[0]);
  }

}
