import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class DatasetviewerService {

  data: any;
  results: any[] = [];
  tickets: XLSX.WorkSheet;
  process: any;

  scoringResult;
  scoringResultInfo: string;
  filename: string;

  currentTable;

  async urlData(data) {

    
    const wb: XLSX.WorkBook = XLSX.read(await data.arrayBuffer(), { type: 'buffer' });

    this.data = {};

    wb.SheetNames.forEach(wsname => {

      this.tickets = wb.Sheets[wsname];

      this.data[wsname] = {
        headers: [],
        rows: (XLSX.utils.sheet_to_json(this.tickets, { header: 1 }))
      };

      this.data[wsname].headers.push(this.data[wsname].rows.shift());
    });

    this.currentTable = this.data[wb.SheetNames[0]];
  }

  handleFileNoProcess(e: any) {


    const bstr: string = e.target.result;
    const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });


    this.data = {};

    wb.SheetNames.forEach(wsname => {
      // const wsname: string = 'Tickets';// wb.SheetNames[1];
      this.tickets = wb.Sheets[wsname];
      

      /* save data */
      this.data[wsname] = {
        headers: [],
        rows: (XLSX.utils.sheet_to_json(this.tickets, { header: 1 }))
      };


      this.data[wsname].headers.push(this.data[wsname].rows.shift());
    });    

    this.currentTable = this.data[wb.SheetNames[0]];

  }




  constructor(private afs: AngularFirestore, private storage: AngularFireStorage) { }




  findColumnIndex(sheet: string, column: string) {
    const index = this.data[sheet].headers[0].indexOf(column);

    return index;

  }


}
