import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "shortidea"
})
export class ShortideaPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if(!value) return "";
    if (value.length < 10) return value;
    
    return value.substring(0,10).trim()+"..."; //todo gör lite mer smart?
  }
}
