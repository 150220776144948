import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {
  description: any;
  statement: any;
  actor: any;
  filename:string;
  roundid: any;
  createtask: boolean;
  round: any;

  constructor() { }
}
