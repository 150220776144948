import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";

import { ActivatedRoute } from "@angular/router";
import { AngularFirestore } from "@angular/fire/firestore";
import { ProcessEval2, Round, Statement } from "../../round";
import { DataService } from "../../data.service";
import { DummyService } from "../../dummy.service";
import { StepService } from "src/app/step.service";
import { fade, slideUp } from "src/app/animations/animations";

@Component({
  selector: "app-assess",
  templateUrl: "./assess.component.html",
  styleUrls: ["./assess.component.css"],
  animations: [
    fade,
    slideUp
  ]
})
export class AssessComponent implements OnInit {



  currentPEval: any;

  round: Round;

  ratings = ["", 1, 2, 3, 4];


  // processes: ProcessEval2[];

  actors: any[] = [];
  assessments: any;
  pairs: any;
  // statementPairs: any[];
  statementAsses: any[];
  //info: string;


  changeTrigger: number; //todo jävla fullösning
  change(statement) {
    this.changeTrigger = new Date().getMilliseconds();

  //  statement.score = this.calcScore(statement);

  }


  // calcScore(statement: Statement) {
  //   let value = []
  //   value.push(statement.commonHuman)
  //   value.push(statement.commonMachine)

  //   if (!value) value = [];
  //   let total = 0;
  //   let count = 0;
  //   value.forEach(v => {
  //     let value = parseFloat(v);

  //     if (!isNaN(value) && value > 0) {
  //       total += value;
  //       count++;
  //     }
  //   });
  //   return total / count;
  // }

  constructor(
    private data: DataService,
    private dummy: DummyService,
    private route: ActivatedRoute,
    private afs: AngularFirestore,
    public step: StepService
  ) {

    step.setStep('1b');
  }

  // showScore() {

  //   alert('This score is totally random and a placeholder for score based on a dataset')

  // }



  ngOnInit() {

    this.route.paramMap.subscribe(params => {
      if (params.has("id")) {
        let id = params.get("id");


        this.step.loadRound(id);
     
      }
    });
  }

 

  // getAllAsses(id: string): any {
  //   alert("used? ");
  //   //console.log("used?");
  // }
  // getAllPairs(roundid): any {
  //   alert("used? ");
  //   //console.log("used?");
  //   this.pairs = [];

  //   let ptemp = [];



  // }



  // get humanAvg() {
  //   alert("used? ");
  //   //console.log("used?");
  //   return -1;
  // }

  // createIdea() {
  //   alert("used? ");
  //   //console.log("used?");
  //   return this.data.createDummyIdea();
  // }

  // createMachineAss() {
  //   alert("used? ");
  //   //console.log("used?");
  //   let d = new Date();
  //   let ass = {
  //     rating: Math.round(Math.random() * 5),
  //     comment: this.dummy.comment(5),
  //     created: d.getTime(),
  //     expanded: false
  //   };
  //   return ass;
  // }

  expand(i) {
    alert("used? ");
    //console.log("used?");
    if (this.currentPEval.statements[i].expanded)
      this.currentPEval.statements[i].expanded = false;
    else this.currentPEval.statements[i].expanded = true;
  }

  collapseAll() {
    alert("used? ");
    //console.log("used?");
    this.currentPEval.statements.forEach(element => {
      element.expanded = false;
    });
  }

  // addIdea(statement) {
  //   alert("used? ");
  //   //console.log("used?");

  //   if (!statement.pairs) statement.pairs = [];
  //   statement.pairs.push(this.createIdea());
  // }

  removeIdea(statement, i) {
    alert("used? ");
    //console.log("used?");
    statement.ideas.splice(i, 1);
  }



  removeHumasAss() {
    alert("used? ");
    //console.log("used?");
  }

  // addMachineAss(statement) {
  //   alert("used? ");
  //   //console.log("used?");
  //   //   //console.log("adding machine");
  //   statement.machineAss.push(this.createMachineAss());
  // }

  @ViewChildren("cmp") components: QueryList<any>;

  focusIdea(statement) {
    alert("used? ");
    //console.log("used?");
    statement.expanded = true;

  }

  showjson = false;
  toggleJSON() {
    alert("used? ");
    //console.log("used?");
    this.showjson = !this.showjson;

  }

  setProcess(process) {
    alert("used? ");
    //console.log("used?");
    this.currentPEval = this.round.processAss2[process];
  }

  //   save() {
  //     alert("used? ");
  //     //console.log("used?");
  //   //  this.info = "Saving...";
  //     let promises = [];
  //     promises.push(
  //       this.afs
  //         .collection("nrounds")
  //         .doc(this.round.id)
  //         .update(this.round)
  //     );
  //     this.round.processes.forEach(p => {
  //       p.statements.forEach(statement => {
  //         statement.pairs.forEach(pair => {
  //           //console.log("save pair", pair);

  //           promises.push(
  //             this.afs
  //               .collection("statementPairs")
  //               .doc(pair.id)
  //               .set(pair)
  //           );
  //         });

  //         statement.humanAss.forEach(ass => {
  //           //      //console.log("save ass", ass);

  //           promises.push(
  //             this.afs
  //               .collection("statementAsses")
  //               .doc(ass.id)
  //               .set(ass)
  //           );
  //         });
  //       });
  //     });

  //     Promise.all(promises).then(() => {
  //       //console.log("Saved " + promises.length + " things");

  // //      this.info = "Done saving.";

  //       setTimeout(() => {
  //      //   this.info = "";
  //       }, 3000);
  //     });

  //     return;

  //   }
  updateHumanAss(humanAss: any): any {
    alert("used? ");
    //console.log("used?");
    //  //console.log("humanAss", humanAss);

    if (humanAss)
      humanAss.forEach(ha => {
        //   //console.log("statement ",ha.actor.name)
        //   //console.log("statement id",ha.statementId)

        this.actors.forEach(actor => {
          actor.processAssessments.forEach(pa => {
            pa.statementAssessments.forEach(sa => {
              ////console.log( actor.name +" a statement id", sa.statementId);

              if (
                actor.id === ha.actor.id &&
                ha.statementId === sa.statementId
              ) {
                //console.log("Found a match");
                //console.log("ha", ha);
                //console.log("sa", sa);
                sa = ha;
                //console.log("updated sa?", sa);
                ////console.log( actor.name +" a statement id", sa.statementId);
              }
            });
          });
        });
      });
  }
}
