import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";

import { isNullOrUndefined } from "util";
import { AngularFireAuth } from "@angular/fire/auth";
import { element } from "@angular/core/src/render3/instructions";
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,

  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):Observable<boolean> {


    return this.auth.isAuth();
 
  }
}
