import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-everything',
  templateUrl: './everything.component.html',
  styleUrls: ['./everything.component.css']
})
export class EverythingComponent implements OnInit {
  statementAsses: any;
  processes: any;
  actors: any;
  rounds: any;
  users: any;


  constructor(private afs:AngularFirestore) { }

  ngOnInit() {
  }



  loadProcesses(){
   this.processes= this.afs
    .collection("processes").valueChanges();
    
  }

  loadActors(){
   this.actors= this.afs
    .collection("actors").valueChanges();
    
  }

  loadRounds(){
   this.rounds= this.afs
    .collection("nrounds").valueChanges();
    
  }
  loadUsers(){
   this.users= this.afs
    .collection("users").valueChanges();
    
  }



  load(){

   this.statementAsses= this.afs
    .collection("statementAsses").valueChanges();


  }

}
