import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { PlatformLocation } from '@angular/common';
import { AngularFireFunctions } from '@angular/fire/functions';



@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.css']
})
export class LinksComponent implements OnInit {
  id: string;
  round: any;
  domainUrl: string = "yo";
  sent: any;

  constructor(private afs: AngularFirestore, private route: ActivatedRoute, private auth: AuthService, private router: Router, private loc: PlatformLocation, private fun: AngularFireFunctions) {


    let url = window.location.href;

    this.domainUrl = url.replace(loc.pathname, '');
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      //let item=new Item();
      if (params.has("id")) {
        this.id = params.get("id");




        this.afs
          .collection("nrounds")
          .doc(this.id)
          .get()
          .subscribe(o => {
            this.round = o.data();
            //console.log("hittade service", this.round);
          });

      }
    });
  }


  mail() {
    console.log("I got here!");

    if (this.sent) return;  //stoppa spam
    this.sent = true;
    //console.log("mail", this.round)


    let sendList = [];

    this.round.actors.forEach(actor => {

      if (actor.email) {
        //console.log(actor);

        sendList.push({
          email: actor.email,
          name: actor.name,
          link: 'http://hdms.app/' + actor.link,
          process: this.round.process.name,
          subject: 'You have been invited to use HDMS.app!'
        })
      }
    });

    console.log("I got here2", sendList);

    //console.log(sendList)

    sendList.forEach(mail => {

      let mail1 = {
        to: [mail.email],
        message: {
          subject: mail.subject,
          text: mail.link
        }
      }



      this.afs.collection("mail").add(mail1);

      // const callable = this.fun.httpsCallable('genericEmail');
      // callable(mail).subscribe();
    });
  }
}
