import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';

import { AppComponent } from './app.component';
import { TestViewComponent } from './main/test-view/test-view.component';
import { ShortideaPipe } from './shortidea.pipe';

import { RatingAvgPipe } from './rating-avg.pipe';
import { FormsModule } from '@angular/forms';
import { RoundsComponent } from './main/rounds/rounds.component';
import { RoundComponent } from './main/round/round.component';
import { ProcessesComponent } from './main/processes/processes.component';
import { Round } from './round';
import { RouterModule, Routes } from '@angular/router';
import { NotfoundComponent } from './notfound/notfound.component';
import { AssessComponent } from './main/assess/assess.component';
import { HeaderComponent } from './main/header/header.component';
import { ProcessComponent } from './main/process/process.component';
import { MachineDataViewComponent } from './main/machine-data-view/machine-data-view.component';
import { ActorAssComponent } from './main/actor-ass/actor-ass.component';
import { IdeasComponent } from './main/ideas/ideas.component';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { CreateFromFileComponent } from './main/processes/create-from-file/create-from-file.component';
import { AuthGuard } from './auth.guard';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { ServicesComponent } from './services/services.component';
import { ServiceComponent } from './service/service.component';
import { LinksComponent } from './links/links.component';
import { AssPairComponent } from './ass-pair/ass-pair.component';
import { InterdependencyComponent } from './interdependency/interdependency.component';
import { ImplementationComponent } from './implementation/implementation.component';
import { EvaluationComponent } from './evaluation/evaluation.component';



import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScorePipe } from './score.pipe';
import { HdmsSelectComponent } from './hdms-select/hdms-select.component';
import { HdmsSolutionsComponent } from './hdms-solutions/hdms-solutions.component';
import { HdmsResultComponent } from './hdms-result/hdms-result.component';
import { HdmsHummanassComponent } from './hdms-hummanass/hdms-hummanass.component';
import { HdmsScoringComponent } from './hdms-scoring/hdms-scoring.component';
import { ScorecolorPipe } from './scorecolor.pipe';
import { AssInfoComponent } from './ass-info/ass-info.component';
import { CsvParseComponent } from './csv-parse/csv-parse.component';
import { XlsParseComponent } from './xls-parse/xls-parse.component';
import { SortPipe } from './sort.pipe';
import { StepHeaderComponent } from './step-header/step-header.component';
import { ValuationComponent } from './valuation/valuation.component';
import { VisualisationComponent } from './visualisation/visualisation.component';
import { StepProgressComponent } from './step-progress/step-progress.component';
import { ParaTableComponent } from './para-table/para-table.component';
import { StepHelpComponent } from './step-help/step-help.component';
import { IdeaChartComponent } from './idea-chart/idea-chart.component';
import { UploadDocumentComponent } from './upload-document/upload-document.component';
import { UploadDocumentModalComponent } from './upload-document-modal/upload-document-modal.component';
import { TesterComponent } from './tester/tester.component';

import { AngularFireStorageModule } from '@angular/fire/storage';
import { HttpClientModule } from '@angular/common/http';
import { FirstPageComponent } from './first-page/first-page.component';
import { SmallHeaderComponent } from './small-header/small-header.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { Step1cComponent } from './step1c/step1c.component';
import { Step1cExpansionComponent } from './step1c-expansion/step1c-expansion.component';
import { ModalInfoComponent } from './modal-info/modal-info.component';
import { TempTestComponent } from './temp-test/temp-test.component';
import { EverythingComponent } from './everything/everything.component';
import { DatasetviewerComponent } from './datasetviewer/datasetviewer.component';
import { MachineInfoAndGraphComponent } from './machine-info-and-graph/machine-info-and-graph.component';
import { SelectScoreComponent } from './select-score/select-score.component';
import { NewIdeaChartComponent } from './new-idea-chart/new-idea-chart.component';
import { MachineIdeasPipe } from './machine-ideas.pipe';
import { HumanIdeasPipe } from './human-ideas.pipe';




const appRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'createround', component: RoundComponent, },
  { path: 'editround', component: RoundComponent, },
  { path: 'assess', component: AssessComponent, },
  { path: 'round/new', component: RoundComponent, },

  { path: 'processes/edit/:id', component: ProcessComponent, },
  { path: 'processes/createFromJSON', component: CreateFromFileComponent, },
  { path: 'processes/create', component: ProcessComponent, },
  { path: 'processes', component: ProcessesComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'process', component: ProcessComponent, canActivate: [AuthGuard], },
  { path: 'actor', component: ActorAssComponent, },
  { path: 'ideas/:id', component: IdeasComponent, },
  { path: 'service/:id', component: ServiceComponent, },
  { path: 'links/:id', component: LinksComponent, },


  /*Lu's */
  { path: 'interdependency', component: InterdependencyComponent },
  { path: 'implementation', component: ImplementationComponent },
  { path: 'evaluation', component: EvaluationComponent },
  { path: 'csv', component: CsvParseComponent },
  { path: 'xls', component: XlsParseComponent },
  { path: 'test', component: TesterComponent },
  { path: 'testExcel', component: TempTestComponent },
  { path: 'datasetviewer/:id', component: DatasetviewerComponent },




 // { path: '', component: RoundsComponent, canActivate: [AuthGuard] },
  // { path: '', component: FirstPageComponent, canActivate: [AuthGuard] },
  { path: '', component: RoundsComponent, canActivate: [AuthGuard] },
  { path: 'projects', component: RoundsComponent, canActivate: [AuthGuard] },
  
  { path: 'project/new', component: RoundComponent, canActivate: [AuthGuard] },
  { path: 'project/:id', component: AssessComponent, },
  { path: 'project/edit/:id', component: RoundComponent, },
  
  { path: 'notfound', component: NotfoundComponent, },
  { path: 'everything', component: EverythingComponent, },
  { path: ':link', component: ActorAssComponent, },
  { path: '**', component: NotfoundComponent, }
]


@NgModule({
  declarations: [
    AppComponent,
    TestViewComponent,
    ShortideaPipe,

    RatingAvgPipe,

    RoundsComponent,

    RoundComponent,

    ProcessesComponent,
    NotfoundComponent,
    AssessComponent,
    HeaderComponent,
    ProcessComponent,
    MachineDataViewComponent,
    ActorAssComponent,
    IdeasComponent,
    CreateFromFileComponent,
    LoginComponent,
    MainComponent,
    ServicesComponent,
    ServiceComponent,
    LinksComponent,
    AssPairComponent,
    InterdependencyComponent,
    ImplementationComponent,
    EvaluationComponent,
    ScorePipe,
    HdmsSelectComponent,
    HdmsSolutionsComponent,
    HdmsResultComponent,
    HdmsHummanassComponent,
    HdmsScoringComponent,
    ScorecolorPipe,
    AssInfoComponent,
    CsvParseComponent,
    XlsParseComponent,
    SortPipe,
    StepHeaderComponent,
    ValuationComponent,
    VisualisationComponent,
    StepProgressComponent,
    ParaTableComponent,
    StepHelpComponent,
    IdeaChartComponent,
    UploadDocumentComponent,
    UploadDocumentModalComponent,
    TesterComponent,
    FirstPageComponent,
    SmallHeaderComponent,
    WelcomeComponent,
    Step1cComponent,
    Step1cExpansionComponent,
    ModalInfoComponent,
    TempTestComponent,
    EverythingComponent,
    DatasetviewerComponent,
    MachineInfoAndGraphComponent,
    SelectScoreComponent,
    NewIdeaChartComponent,
    MachineIdeasPipe,
    HumanIdeasPipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false }
    ),
    AngularFireStorageModule,
    HttpClientModule
 
  ],
  providers: [AngularFirestore, AngularFireAuth, AuthGuard,AngularFireFunctions],
  bootstrap: [AppComponent]
})
export class AppModule { }
