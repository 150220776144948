import { Injectable } from "@angular/core";
declare var require: any

const firstNamesJSON = require('../dummy/firstnames.json');
const loremJSON = require('../dummy/lorem.json');
const pizza = require('../dummy/pizzeria.json');
@Injectable({
  providedIn: "root"
})
export class DummyService {
  getArrayNumbersInSequence(n: number, start: number, step): number[] {
  let arr=[];

  for (let i = 0; i < n; i++) {
    
    arr.push(start+=step);
  }

  return arr;
  }
  getWordArray(arg0: number): any {
   
let arr=[];

for (let index = 0; index < arg0; index++) {
  
  arr.push(this.sentence(1,1,false));
  
}
return arr;
  }
  getArray(arg0: number, arg1: number, arg2: number): any {
  
    let arr=[];

    for (let index = 0; index < arg0; index++) {
     arr.push(this.randomInt(arg1,arg2));
      
    }
return arr;
  }
 
 
  randomDate(): any {
    let from = new Date();
    from.setFullYear(from.getFullYear() - 1);

    return new Date(this.randomInt(from.getTime(), Date.now()));
  }

  sentence(wordsMin: number, wordsMax: number, period: boolean): string {
    let words = this.randomInt(wordsMin, wordsMax);
    let s: string = "";
    let prevWord: string = "";
    for (let index = 0; index < words; index++) {
      let word: string;

      let tries = 0;
      while (tries++ < 10) {
        word = this.getRandomItem(this.loremWords);
        if (word != prevWord) break;
      }
      prevWord = word;
      s += word;
      s += " ";
    }

    s = s.toLowerCase();
    s = s.trim();
    s = this.capitalizeFirstLetter(s);
    if (period) s += ".";
    return s;
  }
  randomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  uid(n: number): any {
    var text = "";
    var possible = "abcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < n; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }
  firstName(): string {

    return firstNamesJSON[Math.floor(Math.random() * firstNamesJSON.length)];
  }
  loremWords: string[] = [];

  constructor() {
    this.loadJSONS();
  
  }

  getRandomItem(arr: any[]) {
    return arr[Math.floor(Math.random() * arr.length)];
  }

  comment(n) {
    let c: string = "";

    for (let index = 0; index < n; index++) {
      c += this.sentence(3, 7, true) + " ";
    }

    c.trim();
    return c;
  }
  capitalizeFirstLetter(s: string) {
    let c = s.charAt(0).toUpperCase();
    s = s.slice(1);

    return c + s;
  }

names=[];

getPizza():string{
  return JSON.stringify(pizza,null,3);
}
  loadJSONS(){
//     firstNamesJSON.forEach(name => {

//   this.names.push(name);
// });
// //console.log("Importerade "+firstNamesJSON.length+ " namn");
// //console.log("Importerade "+JSON.stringify(pizza)+ " namn");
  //lägg i fil
  let s ="";

  loremJSON.forEach(p => {
    s+=p;
  });
 

s = s.replace(/\./g, "");
s = s.replace(/\,/g, "");

let temp = "apan gillar bananer, chips och popcorn";
this.loremWords = s.split(" ");

this.loremWords.forEach(element => {
  element = element.toLowerCase();

  element.trim();
});




  }
}
