import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Round } from './round';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StepService {
  //   this.
  //   this.phase = 3;
  //   break;
  // case '5':
  //   this.
  //   this.phase = 3;
  //   break;
  dataseturl(arg0: string, dataseturl: any) {
    throw new Error('Method not implemented.');
  }

  statementAsses: any[];
  public round: Round;
  docs: any;

  individual: boolean;
  hideHomeIcon: boolean;


  stepDic = {
    '0': {
      step: '0',
      phase: 0,
      subheader: "Preparation"
    },
    '1a': {
      step: '1a',
      phase: 1,
      subheader: "Step 2A: Individual assessment"
    },
    '1b': {
      step: '1b',
      phase: 1,
      subheader: "Step 2B: Compare the results of Individual assessment"
    },
    '1c': {
      step: '1c',
      phase: 1,
      subheader: 'Step 2C: Machine assessment'

    },
    '1d': {
      step: '1d',
      next: '2',
      phase: 1,
      subheader: "Step 2D: Human and machine assessment comparison"
    },

    '2': {
      step: '2',
      phase: 2,
      subheader: "Step 3: Identify solutions for the selected problem(s)"
    },

    '3a': {
      step: '3a',
      phase: 3,
      subheader: "Step 4A: Valuation"
    },

    '3b': {
      step: '3b',
      phase: 3,
      subheader: "Step 4B: Visualization and decision made"
    },
    '4': {
      step: '4',
      phase: 4,
      subheader: ''
    }
  }


  roundName: any;
 
  step: any;
  processname = ""

  header = ""
  subheader: string;
  phase: number = 1;
  infotext: string;


  steps = [];

  constructor(private afs: AngularFirestore) {

    for (const key in this.stepDic) {
      this.steps.push(this.stepDic[key])
    }



    this.steps.sort(function (a, b) {
      var nameA = a.step.toLowerCase(), nameB = b.step.toLowerCase()
      if (nameA < nameB) //sort string ascending
        return -1
      if (nameA > nameB)
        return 1
      return 0 //default return value (no sorting)
    })


    let prev = "";
    for (let i = 0; i < this.steps.length; i++) {
      let el = this.steps[i];
      this.stepDic[el.step].value = i;

      if (i > 0) this.stepDic[el.step].prev = prev;
      if (i < this.steps.length - 1) this.stepDic[el.step].next = this.steps[i + 1].step;

      prev = el.step;

    }
    // this.updateData()
  }



  getAllAsses(id: string): any {
    let assP = this.afs
      .collection("statementAsses")
      .ref.where("roundId", "==", id)
      .get()
      .then(a => {
        this.statementAsses = [];

        a.docs.forEach(doc => {
          this.statementAsses.push(doc.data());
        });


        this.statementAsses.forEach(ass => {
          //console.log("ass", ass)
          let p = this.round.process;
          //  this.round.processes.forEach(p => {
          p.statements.forEach(statement => {


            if (!statement.humanAss) statement.humanAss = [];

            if (ass.statementId === statement.id) {
              if (!ass.name) ass.name = ass.id;

              statement.humanAss.push(ass);

              //    console.log(ass)

              //   this.change(statement); //todo update avg
            }
          });
          //  });
        });
      });
  }


  loadActorFiles(round: any, actor: any) {
    // this.afs.collection('Comments', ref => ref.where('user', '==', 'sss'))
    //     .valueChanges({ idField:'a' });

    // this.afs.collection('apa').valueChanges({idField:"" });

    //console.log("getting files from ", round, " actor ", actor);
    this.afs
      .collection<Round>("nrounds")
      .doc(round).collection("files", f => f.where('actor', '==', actor))
      .valueChanges({ idField: "id" }).subscribe(data => {
        this.docs = data;
      });
  }

  loadFiles(id: string) {
    //documents
    //console.log("getting files from ", id);
    this.afs
      .collection<Round>("nrounds")
      .doc(id).collection("files")

      .valueChanges({ idField: "id" }).subscribe(data => {
        this.docs = data;
      });

  }

  loadRound(id: string) {

    this.loadFiles(id);

    this.afs
      .collection<Round>("nrounds")
      .doc(id)

      .valueChanges()

      .subscribe(r => {

       // console.log("ValueChanges!")

        this.round = r as Round;

        this.setRoundName(this.round.name);
        if (!this.round.processes) this.round.processes = [];


        //console.log("round", this.round)
        //    this.round.processes.forEach(p => {

        this.round.process.statements.forEach(statement => {

          //  //console.log("STEP sid: " + statement.sid)
          //  statement.pairs = [];
          statement.humanAss = [];







          if (statement.machineScore) {
            statement.commonMachine = statement.machineScore.score;
            let summary = statement.machineScore.data.text[statement.machineScore.data.text.length - 1];

            statement.commonMachineDesc = summary;
          } else {


            statement.commonMachine = "";
            statement.commonMachineDesc = "Could not calculate score for statement.";
          }


        });
        // });


        //   this.step.setProcessName(this.round.processes[0].name);
        this.setProcessName(this.round.process.name);

        //   this.getAllPairs(id);

        this.getAllAsses(id);

        //  this.currentPEval = this.round.processes[0];
        //    this.currentPEval = this.round.process;


      });
  }
  

  setRoundName(roundName: any) {
    this.roundName = roundName;
    this.header = roundName;
  }

  setProcessName(processname: string) {
    this.processname = processname;
  }


  closeExpanded() {

    this.round.process.statements.forEach(s => {

      ////s.scoringExpanded="false";

      if (s.humanAssExpanded) s.humanAssExpanded = false;  //1b
      if (s.scoringExpanded) s.scoringExpanded = false;  //1c

      if (s.scoringExpanded) s.scoringExpanded = false; //1d todo separera från ovanstående
    //  console.log(s.scoringExpanded);
    });
  }

  saveTask() {
    //  this.afs.collection('tasks').doc(task.id).set(task);

   // console.log("saving", this.round)
    this.closeExpanded();
    this.afs.collection('nrounds').doc(this.round.id).set(this.round).catch(err=>{
  //    console.log("save task ERROR",err)
    });
  }


  addDoc(roundid, doc) {

    //console.log("roundid", roundid, "doc", doc);
    this.afs.collection('nrounds').doc(roundid).collection('files').add(doc);
  }

  removeDoc(roundid, doc) {
    //console.log("removing from roundid", roundid, "doc", doc);
    this.afs.collection('nrounds').doc(roundid).collection('files').doc(doc.id).delete();
  }

  // back_old() {
  //   this.saveTask();
  //   this.step--;
  //   this.updateData();
  // }

  hideHome() {
    this.hideHomeIcon = true;
  }

  showHome() {
    this.hideHomeIcon = false;
  }

  setStep(s: string) {

  if(this.round)  this.saveTask();
    //console.log("yo!")

    let x = this.stepDic[s];

    if (s === '1a') this.individual = true;
    else this.individual = false;

    //todo temp
    //  s=2;
    //console.log("setting step " + s)
    this.step = x;
    this.updateData();
  }


  next() {
    console.log("next");

  
    this.setStep(this.step.next)
  }

  back() {

    console.log("back");


    this.setStep(this.step.prev)
  }

  clickProgress(step) {
    console.log("click p");
    this.saveTask();
    this.step = step;
    this.updateData();

  }

  updateData() {


    //todo lägg i dic

    // this.header = "roundname";

    if(this.step) this.subheader = this.step.subheader;
    if(this.step)  this.phase = this.step.phase;
    return;

    switch (this.step) {

      case '0':
        this.subheader = "Step 1A: Individual assessment";
        this.phase = 1;
        break;

      case '1':
        this.subheader = "Step 1B: Individual assessment comparison";
        this.phase = 1;
        break;

      case '2':
        this.subheader = "Step 1C: Human and machine assessment comparison";
        this.phase = 1;
        break;

      case '3':
        this.subheader = "Step 2: Identify solutions for the selected problem(s)";
        this.phase = 2;
        break;

      case '4':
        this.subheader = "Step 3A: Valuation";
        this.phase = 3;
        break;

      case '5':
        this.subheader = "Step 3B: Visualization and decision made";
        this.phase = 3;
        break;








      default:
        break;
    }

    //console.log("upp", this.step)























  }



}
