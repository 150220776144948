import { Injectable } from '@angular/core';

import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  tableDone = false;

  data: any;
  results: any[] = [];
 // tickets: XLSX.WorkSheet;


  tries: any[] = [];

  scoringResult;
  scoringResultInfo: string;

  constructor() { }

  handleFile(e: any) {

    console.log("yo")





    /* read workbook */
    const bstr: string = e.target.result;
    const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

    /* grab first sheet */

    const sheets = ['Tickets', 'Alerts'];

    this.data = {};

    sheets.forEach(wsname => {
      // const wsname: string = 'Tickets';// wb.SheetNames[1];
    const sheet= wb.Sheets[wsname];


      /* save data */
      this.data[wsname] = {
        headers: [],
        rows: (XLSX.utils.sheet_to_json(sheet, { header: 1 }))
      };


      this.data[wsname].headers.push(this.data[wsname].rows.shift());
    });


    this.data.Tickets.rows= this.data.Tickets.rows.slice(0,5);
    this.data.Alerts.rows= this.data.Alerts.rows.slice(0,5);
    console.log(this.data)
    this.tableDone = true;
    let failed = 0;
    let success = 0;



    if (success < 9) {
      this.scoringResult = "-1";
      this.scoringResultInfo = "Failed calculating score."
    } else {
      this.scoringResult = "1";
      this.scoringResultInfo = ""
    }

  }

}
