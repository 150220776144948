import { Injectable } from "@angular/core";
import {
  Process,
  Statement,
  Round,
  Actor,
  StatementAssement,
  Idea,
  BaseMachineData,
  MachineAss
} from "./round";
import { DummyService } from "./dummy.service";
import { flatten, debugOutputAstAsTypeScript } from "@angular/compiler";
import { throwError, Observable } from "rxjs";
import { sanitizeHtml } from "@angular/core/src/sanitization/sanitization";
import { AuthService } from "./auth.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { map } from "rxjs/operators";
export interface IProcess {
  name: string;
  description: string;
  statements: any[];
  test;
}

@Injectable({
  providedIn: "root"
})
export class DataService {
  calcHumanAvg(statement: any) {



    // //console.log("calc ",statement.humanAss)


    let total = 0;
    let count = 0;
    if (statement.humanAss) {
      statement.humanAss.forEach(v => {

        let changedrating = parseFloat(v.changedrating);
        ////console.log(isNaN(changedrating))

        let value = parseFloat(v.rating);
        //   //console.log("value "+value);


        if (!isNaN(changedrating)) value = changedrating;

        if (!isNaN(value) && value > 0) {
          total += value;
          count++;
        }

      });
    }
    else {
      //     //console.log("no ass")
      statement.commonHuman = null;
      return;
    }

    if (count == 0) {

      statement.commonHuman = null;
      return null;

    }
    let avg: number = total / count;
    // //console.log("state avg "+avg);
    statement.commonHuman = avg;


  }
  processes: Observable<Process[]>;
  addProces(generatedProcess: Process): any {
    let p = <IProcess>{};

    //console.log("gen", generatedProcess);
    p.name = generatedProcess.name;
    p.description = generatedProcess.description;

    p.statements = generatedProcess.statements.map(s => {
      return {
        text: s.text,
        description: s.description,
        sid: s.sid,
        machineShowInfo: s.machineShowInfo,
        machineShowExample: s.machineShowExample,
        machineShowDocuments: s.machineShowDocuments,


        //  order:0, //function add last?
        id: this.afs.createId(),
        // baseMachineData: s.baseMachineData.map(smd => {
        //   let values;
        //   if (smd.viewType === "table") {
        //     values = [];

        //     smd.values.forEach(row => {
        //       values.push({ row }); //todo firestore nested arrays support issue
        //     });
        //     //console.log("rows?", smd.values);
        //   } else {
        //     values = smd.values.map(v => {
        //       return Number(v);
        //     });
        //   }

        //   return {
        //     values: values,

        //     labels: smd.labels,
        //     description: smd.description,
        //     name: smd.name,
        //     viewType: smd.viewType
        //     // rating:smd.rating, //todo
        //   };
        // })
      };
    });

    //p=generatedProcess as IProcess;

    let id = this.afs.createId();

    let userid = this.auth.userid;

    this.afs
      .collection("processes")
      .doc(id)
      .set(p)
      .then(pro => {
        //    //console.log("pro?", pro);
      });

    // this.afs.collection('user').doc(userid).update({yo:"fu2"});

    this.afs
      .collection("users")
      .doc(userid)
      .get()
      .toPromise()
      .then(u => {
        let data = u.data();

        if (!data.userProcesses) data.userProcesses = [];
        data.userProcesses.push(id);

        this.afs
          .collection("users")
          .doc(userid)
          .update({ userProcesses: data.userProcesses });
      });

    // this.afs.collection('user').doc(this.auth.userid).get().subscribe(obj=>{
    // //console.log("user? ",obj.data());
    //   let userProcesses=obj.data().userProcesses;

    //   if(!userProcesses) userProcesses=[];
    //   userProcesses.push(id);

    // });
    //this.afs.collection(this.auth.user.'statements').add(p);

    // this.auth.user
  }
  selectedProcess: Process;
  createDummyIdea(): Idea {
    let idea = <Idea>{}; // new Idea();
    idea.text = this.dummy.comment(4);
    idea.problem = "";
    idea.solution = "";
    idea.id = this.afs.createId();
    idea.comment = "";
    (idea.text = this.dummy.comment(5)),
      (idea.source = "Common"),
      // (idea.value = Math.round(Math.random() * 5)),

      (idea.prio = false);
    //    (idea.created = this.dummy.randomDate());

    return idea;
  }
  // createDummyData(): any {
  //   //create processes

  //   this.processes = [];

  //   this.processes.push(this.createPizzaProcess());

  //   for (let index = 0; index < 2; index++) {
  //     this.processes.push(this.createDummyProcess());
  //   }

  //   //console.log("Dummy Proceses", this.processes);

  //   //create rounds
  //   this.rounds = [];

  //   for (let index = 0; index < 5; index++) {
  //     this.rounds.push(this.createDummyRound());
  //   }
  // }
  currentRound: Round;
  setCurrentRound(round: Round): any {
    this.currentRound = round;
  }
  deleteProcess(process: Process): any {
    // let i = -1;
    // this.processes.forEach(p => {
    //   i++;
    //   if (p == process) {
    //     this.processes.splice(i, 1);
    //     return;
    //   }
    // });
  }
  deleteRound(round: Round): any {
    let i = -1;

    this.rounds.forEach(p => {
      i++;
      if (p == round) {
        this.rounds.splice(i, 1);
        return;
      }
    });
  }
  rounds: Round[] = [];
  eRound: Round;

  editRound(round: Round): any {
    this.eRound = round;
  }

  // createDummyRound(): Round {
  //   let round = new Round();

  //   round.name = this.dummy.sentence(2, 3, false);

  //   round.created = this.dummy.randomDate();

  //   round.processes = [];

  //   let processes = this.rndFromArr(this.processes, this.dummy.randomInt(1, 3));

  //   processes.forEach(p => {
  //     round.processes.push(p);
  //   });

  //   round.actors = [];

  //   round.actors.push(this.createDummyActor());
  //   round.actors.push(this.createDummyActor());
  //   round.actors.push(this.createDummyActor());

  //   round.processAss = [];
  //   round.processes.forEach(p => {
  //     let proceEval = new ProcessEval(p);

  //     round.actors.forEach(actor => {
  //       proceEval.addActor(actor);
  //     });

  //     proceEval.statementAsses.forEach(sAss => {
  //       sAss.expanded = false;
  //       sAss.ideas = [];
  //       sAss.ideas.push(this.createDummyIdea());
  //       sAss.ideas.push(this.createDummyIdea());
  //       sAss.machineAss = [];

  //       sAss.statement.baseMachineData.forEach(base => {
  //         sAss.machineAss.push(this.createDummyMachineass(base));
  //       });

  //       sAss.humanAss.forEach(hAss => {
  //         (hAss.comment = this.dummy.comment(5)),
  //           (hAss.rating = this.dummy.randomInt(1, 5));
  //       });
  //     });

  //     round.processAss.push(proceEval);
  //   });

  //   round.processAss[0].statementAsses[0].expanded = true;
  //   return round;
  // }
  createDummyMachineass(base: BaseMachineData): MachineAss {
    let ass = new MachineAss(base);

    ass.comment = this.dummy.comment(3);

    return ass;
    // return {
    //   rating: this.dummy.randomInt(1, 100),
    //   comment: this.dummy.comment(5),
    //   created: this.dummy.randomDate(),
    //   expanded: false
    // };
  }

  rndFromArr(arr: any[], n: number): any {
    var result = new Array(n),
      len = arr.length,
      taken = new Array(len);
    if (n > len)
      throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
      var x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  }
  createDummyActor(): Actor {
    let actor = <Actor>{};

    actor.name = this.dummy.firstName();
    actor.link = this.dummy.uid(4);
    return actor;
  }
  constructor(
    private dummy: DummyService,
    private auth: AuthService,
    private afs: AngularFirestore
  ) {
    //  this.createDummyData();

    this.processes = this.afs
      .collection<Process>("processes")
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(a => {
            //     //console.log("am i running=")
            const data = a.payload.doc.data() as Process;

            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
  }

  frameworks: any[];

  //processes: Process[] = [];

  createPizzaProcess(): Process {
    let p = <Process>{
      statements: [
        {
          text: "The restaurant has a good mix of different pizzas.",
          description: "",
          baseMachineData: [
            {
              viewType: "bar",
              labels: [
                "Kebab-based",
                "Ham-based",
                "Seafood-based",
                "Vegetarian"
              ],
              values: [3, 4, 2, 2],
              name: "Pizza categories",
              description: "Pizza grouped by main ingridient."
            }
          ]
        },
        {
          text: "The restaurant has a good variety of vegetarian options.",
          description: "",
          baseMachineData: [
            {
              viewType: "pie",
              labels: ["Vegetarian", "Non-ven"],
              values: [2, 7],
              name: "Veg vs Non-Veg",
              description: "Veg vs Non-Veg"
            }
          ]
        },
        {
          text:
            "The opening hours of the restaurant match the needs of the customers.",
          description: "",
          baseMachineData: [
            {
              viewType: "line",
              labels: [
                "10:00",
                "11:00",
                "12:00",
                "13:00",
                "14:00",
                "15:00",
                "16:00",
                "17:00",
                "18:00",
                "19:00",
                "20:00",
                "21:00",
                "22:00"
              ],
              values: [2, 7, 35, 25, 19, 10, 10, 12, 16, 15, 11, 13],
              name: "Avg amount of Customers",
              description: ""
            }
          ]
        },
        {
          text: "The differents pizzas are profitable.",
          description: "",
          baseMachineData: [
            {
              viewType: "table",
              labels: [
                "Pizza",
                "Ingriedient cost",
                "Sale price",
                "Profit",
                "Someother value"
              ],
              values: [
                ["Vesuvio", 25, 75, 50, 30],
                ["Kebabpizza", 45, 75, 30, 22]
              ],
              name: "Avg amount of Customers",
              description: ""
            }
          ]
        }
      ],
      name: "Pizzeria Evaluation",
      description:
        "This is an example process of arbitrary statements and data. The context is the evaluation of a Pizza place.",
      created: this.dummy.randomDate()
    };

    //     let process = new Process();

    //     process.name = "Pizzeria Evaluation";
    //     process.description =
    //       "This is an example process of arbitrary statements and data. The context is the evaluation of a Pizza place.";
    //     process.created = this.dummy.randomDate();

    //     let statement1 = new Statement();
    //     statement1.text = "The restaurant has a good mix of different pizzas.";
    // statement1.baseMachineData=[];

    //     let statement2 = new Statement();
    //     statement2.text =
    //       "The restaurant has a good variety of vegetarian options.";
    // statement2.baseMachineData=[];

    // let bsmd2=new BaseMachineData();

    // bsmd2.name="Veg vs Non-Veg";
    // bsmd2.description="Veg vs Non-Veg";

    //     process.statements.push(statement1);
    //     process.statements.push(statement2);

    return p as Process;
  }

  createDummyProcess(): Process {
    let process = <Process>{}; // new Process();

    process.name = this.dummy.sentence(1, 2, false);

    process.created = this.dummy.randomDate();

    for (let index = 0; index < 5; index++) {
      process.statements.push(this.createDummyStatement());
    }

    return process;
  }

  createDummyStatement(): Statement {
    let statement = new Statement();

    statement.text = this.dummy.comment(1);
    statement.description = this.dummy.comment(7);

    statement.baseMachineData = [];

    for (let index = 0; index < 2; index++) {
      statement.baseMachineData.push(this.createDummyBaseMchineData());
    }
    return statement;
  }
  createDummyBaseMchineData(): BaseMachineData {
    let data = new BaseMachineData();

    data.labels = this.dummy.getWordArray(3);
    data.values = [];

    for (let index = 0; index < 3; index++) {
      data.values.push(this.dummy.randomInt(1, 10));
    }
    data.name = this.dummy.sentence(1, 1, false);
    data.description = this.dummy.comment(2);

    switch (this.dummy.randomInt(1, 4)) {
      case 1:
        data.viewType = "pie";
        break;
      case 2:
        data.viewType = "bar";
        break;
      case 3:
        data.viewType = "line";
        break;
      case 4:
        data.viewType = "table";

        data.values = [];

        for (let i = 0; i < 4; i++) {
          data.values.push([]);

          for (let index = 0; index < 3; index++) {
            data.values[i].push(this.dummy.randomInt(1, 10));
          }
        }
        break;
    }

    return data;
  }
}
