import { Component, OnInit, Input } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { StepService } from '../step.service';


@Component({
  selector: 'app-ass-info',
  templateUrl: './ass-info.component.html',
  styleUrls: ['./ass-info.component.css']
})
export class AssInfoComponent implements OnInit {


  @Input()
  item;

  @Input()
  justification;

  @Input()
  showGraph;
  datasetUrl: any;


  constructor(private step:StepService, private storage: AngularFireStorage) { }

  ngOnInit() {
    
    let ref = this.storage.ref('/xls/' + this.step.round.dataset);


    ref.getDownloadURL().subscribe(res => {
    //  console.log("ng", res)
      this.datasetUrl=res;
    })

  }

random(){

  return Math.floor(Math.random()*1000);
}

}
