import { Component, OnInit } from "@angular/core";
import { DataService } from "../../data.service";
import { Router } from "@angular/router";
import { Process } from "../../round";


@Component({
  selector: 'app-processes',
  templateUrl: './processes.component.html',
  styleUrls: ['./processes.component.css']
})
export class ProcessesComponent implements OnInit {

  constructor(public data:DataService,private router:Router) { } //todo privae

  ngOnInit() {
  }

  delete(process:Process){

    if(confirm("Vill du verkligen ta bort "+process.name+"?")){

this.data.deleteProcess(process);
    }
  }


  edit(process:Process){
this.data.selectedProcess=process;
    this.router.navigate(['/process']);


  }

  json(process){
    //console.log(JSON.stringify(process,null,3));
  }

}
