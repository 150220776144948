import { Component, OnInit } from "@angular/core";
import { Process } from "../../round";
import { AngularFirestoreDocument, AngularFirestore } from "@angular/fire/firestore";
import { DataService } from "../../data.service";
import { ActivatedRoute } from "@angular/router";


@Component({
  selector: "app-process",
  templateUrl: "./process.component.html",
  styleUrls: ["./process.component.css"]
})
export class ProcessComponent implements OnInit {
  process: Process;
  ratings = ["", 1, 2, 3, 4, 5];

  id: string;
  angularFirestoreDocument: AngularFirestoreDocument<Process>;
  constructor(
    private data: DataService,
    private route: ActivatedRoute,
    private afs: AngularFirestore
  ) {
    //  this.process=data.createDummyProcess();
  }
  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      //let item=new Item();
      if (params.has("id")) {
        this.id = params.get("id");
      //  //console.log("got id " + id);

        //this.loans = this.collection('processes').valueChanges();

        // this.angularFirestoreDocument = this.afs
        //   .collection<Process>("processes")
        //   .doc( this.id);
          
        //  this.process=this.angularFirestoreDocument.valueChanges();


          this.afs.collection('processes').doc( this.id).get().subscribe(o=>{
           this.process=o.data() as Process;
          });
      }
    });
  }

  save() {
    this.afs
      .collection("processes")
      .doc( this.id)
      .update(this.process);
  }
}
