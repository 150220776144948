import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: '[app-ass-pair]',
  templateUrl: './ass-pair.component.html',
  styleUrls: ['./ass-pair.component.css']
})
export class AssPairComponent implements OnInit {

  @Input() pair: any; 
  constructor() {
    alert("ass-pair")
   }

  ngOnInit() {
  }

}
