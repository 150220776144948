import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'machineIdeas'
})
export class MachineIdeasPipe implements PipeTransform {

  transform(items: any[], filter: Object): any {
    console.log("pipe!")
    if (!items ) {
        return items;
    }
    console.log(items)
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return items.filter(item => item.fromMachine);
}

}
