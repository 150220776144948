import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FactoryService {

  constructor() { }

  createDefaultParameters(): any {
    return {
      evalFactors: [
    
    //    this.createParameter("Competences","Do we have competences?", "feasibility"),


         this.createParameter("Resources","E.g., do we have competences for implementing this ides? do we have resources for implementing this idea?","feasibility"),  
         
      
        this.createParameter("Cost", "E.g., can we reduce cost by implementing this idea?","desirability"),
        this.createParameter("Risk","E.g., is there a low risk for implementing this idea? (The higher the value, the lower the risk)","risk"),
    
        //  this.createParameter("Resources","Do we have resources?","feasibility"),  
         
      
        // this.createParameter("Cost", "Can we reduce cost?","desirability"),
        // this.createParameter("Risk","Is there a risk for implementing this idea?","risk"),
    
      ]
      // ,
      // feasibilities: [       
      //   this.createParameter("Competences"),      
      //   this.createParameter("Resources"),       
      //   this.createParameter("Mandate"),
      // ],
      // effects: [
      //   this.createParameter("Reduced cost"),
      //   this.createParameter("Increased quality"),
      //   this.createParameter("Increased efficiency"),
      // ],
      // risks: [
      //   this.createParameter("Tidsbrist"),
      //   this.createParameter("Resursbrist"),
      //   this.createParameter("Sjukfrånvaro"),
      //   this.createParameter("Example risk"),
      // ]
    }
  }

  createParameter(name: string, desc:string, axis:string){

    return {
      name: name,
      description:desc,
      value: 3,
      weight: 3,
      axis:axis,
      comment: ""
    }

  }
}
