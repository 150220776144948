import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AngularFireAuth } from "@angular/fire/auth";
import { User } from "firebase";
import { map } from "rxjs/operators";
import { AngularFirestore } from "@angular/fire/firestore";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  currentRound: any;
  isAuth(): Observable<boolean> {
    return this.auth.user.pipe(
      map(a => {
      
        if (a) return true;
        else{
          this.router.navigateByUrl('login'); //todo lägg till reff
          return false;
        } 
      })
    );
  }
  setUser(): any {
    throw new Error("Method not implemented.");
  }
  user: Observable<User>;
  userid: string;
  name: string = "";
  loggedIn: boolean = false;
  u: User;
  constructor(public auth: AngularFireAuth, private  afs:AngularFirestore,private router: Router) {
    
 
    // this.auth.user.pipe().subscribe(u => {
    //   //console.log("somethin happende?", u);
    //   this.u = u;
    //   if (u) this.loggedIn = true;
    //   else this.loggedIn = false;
    // });

    // this.user = this.auth.authState.pipe(
    //   switchMap(user => {
    //        //console.log("authied?");
    //     if (user) {

    //       this.loggedIn=true;
    //       return this.afs.doc<User>(`users/${user.uid}`).valueChanges()
    //     } else {
    //       return of(null)
    //     }
    //   })
    // )

    auth.user.pipe().subscribe(o => {
      if (o) {

        //console.log("ooooooo ",o.uid)
        this.userid = o.uid;

        this.loggedIn = true;
        afs
          .collection("users")
          .doc(this.userid)
          .get()
          .toPromise()
          .then(u => {
      //      //console.log("welcome! ", u.data());
            this.name = u.data().name;
            this.currentRound=u.data().currentRound;
          });
      } else this.userid = "";
    //  //console.log("userid:" + this.userid);
    });
  }

  login(n,p) {
    //this.afAuth.auth.signInWithEmailAndPassword("testuser@testuser.testuser","testuser");
    //console.log("loiggin in");
    this.auth.auth.signInWithEmailAndPassword(
     n,p
     
    ).then(result=>{
      if(result.user) this.router.navigateByUrl('');
    });
   
  }
  logout() {
    this.auth.auth.signOut();
    this.name = "";
    this.user = null;
    this.loggedIn = false;
    this.router.navigateByUrl('login');
  }
}
