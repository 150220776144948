import { Component, OnInit } from '@angular/core';
import { StepService } from '../step.service';

@Component({
  selector: 'app-first-page',
  templateUrl: './first-page.component.html',
  styleUrls: ['./first-page.component.css']
})
export class FirstPageComponent implements OnInit {

  constructor(private step: StepService) {

    this.step.hideHome();
  }

  ngOnInit() {

  }
  ngOnDestroy() {
    this.step.showHome();
  }
}
